import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import getNormalSOStatusStringFromInt from '../../utils/getNormalSOStatusStringFromInt';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  Container, Content, FormsArea, OS, OSS, SelectedOS,
} from './styles';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import MenuBar from '../../components/MenuBar';
import Input from '../../components/Input';

function ReturnedOSRegistration() {
  const formRefSearch = useRef(null);
  const formRefReturn = useRef(null);
  const history = useHistory();
  const { addToast } = useToast();

  const [serviceOrders, setServiceOrders] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [showServiceOrder, setShowServiceOrder] = useState(false);
  const [hasSearchedBefore, setHasSearchedBefore] = useState(false);

  const [selectedServiceOrder, setSelectedServiceOrder] = useState();

  const handleSelectServiceOrder = useCallback((os) => {
    setSelectedServiceOrder(os);
    setShowServiceOrder(true);
  }, []);

  const handleSearch = useCallback(
    async (data) => {
      setShowServiceOrder(false);
      setHasSearchedBefore(true);

      try {
        formRefSearch.current.setErrors({});

        const schema = Yup.object().shape({
          search: Yup.string()
            .required('Digite algo para buscar'),
        });

        await schema.validate(data, { abortEarly: false });

        const { search } = data;

        const response = await api.get('/service-orders/normal', {
          params: {
            number: search,
          },
        });

        const serviceOrdersData = response.data;

        setServiceOrders(serviceOrdersData);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefSearch.current.setErrors(errors);

          // return
        }
      }
    },
    [],
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRefReturn.current.setErrors({});

        const schema = Yup.object().shape({
          return_motive: Yup.string()
            .required('Digite algo para cadastrar'),
        });

        await schema.validate(data, { abortEarly: false });

        setShowButton(false);

        const response = await api.post('/service-orders/returned', {
          original_service_order_id: selectedServiceOrder.id,
          return_motive: data.return_motive,
        });

        const newServiceOrder = response.data;

        addToast({
          type: 'success',
          title: 'O.S. de retorno cadastrada com sucesso!',
        });

        history.replace(`/os/${newServiceOrder.id}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefReturn.current.setErrors(errors);

          // return;
        }

        addToast({
          type: 'error',
          title: 'Erro na criacão da O.S.',
          description:
          'Ocorreu um erro ao criar a O.S. Cheque os dados.',
        });
      }
    },
    [history, selectedServiceOrder, addToast],
  );

  return (
    <Container>
      <MenuBar />
      <Content>
        <h1>Abrir O.S. de Retorno</h1>

        <FormsArea>
          <Form ref={formRefSearch} onSubmit={handleSearch}>
            <div className="search">
              <div className="number">
                <p>Número da O.S.</p>
                <Input
                  name="search"
                  containerStyle={{
                    width: '280px',
                  }}
                  type="text"
                />
              </div>
              <button type="submit">Buscar</button>
            </div>
          </Form>

          {!showServiceOrder && serviceOrders.length > 0 ? (
            <OSS>
              {serviceOrders.map((os) => (
                <OS key={os.id}>
                  <strong>{os.number}</strong>
                  <p>{os.product_name}</p>
                  <p>
                    Status:
                    {' '}
                    {getNormalSOStatusStringFromInt(os.status)}
                  </p>
                  <button type="button" onClick={() => handleSelectServiceOrder(os)}>Selecionar</button>
                </OS>
              ))}
            </OSS>
          ) : !showServiceOrder && hasSearchedBefore && <p>Nenhuma O.S. encontrada</p>}

          {showServiceOrder && (
            <SelectedOS>
              <strong>{selectedServiceOrder.number}</strong>
              <p>{selectedServiceOrder.product_name}</p>

              <p>
                Status:
                {' '}
                {getNormalSOStatusStringFromInt(selectedServiceOrder.status)}
              </p>
            </SelectedOS>
          )}

          <Form ref={formRefReturn} onSubmit={handleSubmit}>

            <section className="return-data">
              <strong>Informações do Retorno</strong>

              <div className="reason">
                <p>Motivo do Retorno</p>
                <Input
                  name="return_motive"
                  containerStyle={{
                    width: '876px',
                    marginBottom: '24px',
                  }}
                  type="text"
                />
              </div>

              {showButton && (
                <button type="submit">Salvar</button>
              )}

            </section>
          </Form>
        </FormsArea>

      </Content>
    </Container>
  );
}

export default ReturnedOSRegistration;

import React, { useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import { Container, LoginHeader, Content } from './styles';

function Login() {
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();
  const formRef = useRef(null);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string()
            .required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        await signIn({
          username: data.username,
          password: data.password,
        });

        history.replace('/consulta');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          // return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login. Cheque suas credenciais',
        });
      }
    },
    [history, signIn, addToast],
  );

  return (
    <Container>
      <LoginHeader>
        <h1>Sistema Eletro Rádio Mark</h1>
      </LoginHeader>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Login</h1>
          <Input type="text" name="username" placeholder="Usuário" />
          <Input type="password" name="password" placeholder="Senha" />
          <button type="submit">
            Entrar
          </button>
        </Form>
      </Content>
    </Container>
  );
}

export default Login;

import React, { useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import cep from 'cep-promise';

import * as Yup from 'yup';
import { FiSearch } from 'react-icons/fi';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import { Container, Content } from './styles';

import MenuBar from '../../components/MenuBar';
import Input from '../../components/Input';
import api from '../../services/api';

function ClientRegistration() {
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToast();

  const handleSearchZipCode = () => {
    const zipCodeInput = document.getElementById('zip_code');
    const zipCode = zipCodeInput.value;

    cep(zipCode).then((response) => {
      formRef.current.setFieldValue('address', response.street);
      formRef.current.setFieldValue('state', response.state);
      formRef.current.setFieldValue('city', response.city);
      formRef.current.setFieldValue('neighborhood', response.neighborhood);
    });
  };

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Nome obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/clients', {
          name: data.name,
          cpf: data.cpf,
          cnpj: data.cnpj,
          email: data.email,
          zip_code: data.zip_code,
          address: data.address,
          number: data.number,
          complement: data.complement,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          phone1: data.phone1,
          phone2: data.phone2,
          phone3: data.phone3,
        });

        addToast({
          type: 'success',
          title: 'Cliente Cadastrado!',
          description:
            'O cliente foi cadastrado com sucesso',
        });

        history.replace('/abrir-os-normal');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro do cliente',
          description:
            'Ocorreu um erro ao cadastrar o cliente. Cheque os dados. Esse CPF já pode estar cadastrado!',
        });
      }
    },
    [history, addToast],
  );

  return (
    <Container>
      <MenuBar />
      <Content>
        <h1>Cadastrar Cliente</h1>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <section className="client-data">

            <div className="name-email">
              <div className="name">
                <p>Nome Completo</p>
                <Input
                  name="name"
                  type="text"
                  containerStyle={
                  {
                    width: '500px',
                    height: '30px',
                    marginRight: '16px',
                  }
                }
                />
              </div>
              <div className="email">
                <p>Email</p>
                <Input
                  name="email"
                  type="text"
                  containerStyle={
                  {
                    width: '340px',
                  }
                }
                />
              </div>
            </div>

            <div className="cpf-phones">
              <div className="cpf">
                <p>CPF</p>
                <Input
                  name="cpf"
                  type="text"
                  containerStyle={
                  {
                    width: '175px',
                    marginRight: '16px',
                  }
                }
                />
              </div>
              <div className="cnpj">
                <p>CNPJ</p>
                <Input
                  name="cnpj"
                  type="text"
                  containerStyle={
                  {
                    width: '175px',
                    marginRight: '34px',
                  }
                }
                />
              </div>
              <div className="phone1">
                <p>Telefone 1</p>
                <Input
                  name="phone1"
                  type="text"
                  containerStyle={
                  {
                    width: '120px',
                    marginRight: '16px',
                  }
                }
                />
              </div>
              <div className="phone2">
                <p>Telefone 2</p>
                <Input
                  name="phone2"
                  type="text"
                  containerStyle={
                  {
                    width: '120px',
                    marginRight: '16px',
                  }
                }
                />
              </div>
              <div className="phone3">
                <p>Telefone 3</p>
                <Input
                  name="phone3"
                  type="text"
                  containerStyle={
                  {
                    width: '120px',
                  }
                }
                />
              </div>
            </div>
          </section>

          <section className="client-address">
            <strong>Endereço</strong>

            <div className="cep-street-number">
              <div className="cep">
                <p>CEP</p>
                <Input
                  id="zip_code"
                  name="zip_code"
                  type="text"
                  containerStyle={
                  {
                    width: '260px',
                    marginRight: '0px',
                  }
                }
                />
              </div>
              <div className="search-zip-code">
                <button type="button" onClick={handleSearchZipCode}>
                  <FiSearch />
                </button>
              </div>
              <div className="street">
                <p>Rua</p>
                <Input
                  name="address"
                  type="text"
                  containerStyle={
                  {
                    width: '440px',
                    marginRight: '16px',
                  }
                }
                />
              </div>
              <div className="number">
                <p>Nº</p>
                <Input
                  name="number"
                  type="text"
                  inputStyle={{
                    width: '56px',
                  }}
                  containerStyle={
                  {
                    width: '70px',
                  }
                }
                />
              </div>
            </div>

            <div className="district-city-state">
              <div className="district">
                <p>Bairro</p>
                <Input
                  name="neighborhood"
                  type="text"
                  containerStyle={
                  {
                    width: '250px',
                    marginRight: '16px',
                  }
                }
                />
              </div>
              <div className="city">
                <p>Cidade</p>
                <Input
                  name="city"
                  type="text"
                  inputStyle={{
                    width: '140px',
                  }}
                  containerStyle={
                  {
                    width: '200px',
                    marginRight: '16px',
                  }
                }
                />
              </div>
              <div className="state">
                <p>Estado</p>
                <Input
                  name="state"
                  type="text"
                  inputStyle={{
                    width: '56px',
                  }}
                  containerStyle={
                  {
                    width: '70px',
                    marginRight: '16px',
                  }
                }
                />
              </div>
              <div className="complement">
                <p>Complemento</p>
                <Input
                  name="complement"
                  type="text"
                  containerStyle={
                  {
                    width: '250px',
                  }
                }
                />
              </div>
            </div>
          </section>
          <button type="submit">Salvar</button>
        </Form>

      </Content>
    </Container>
  );
}

export default ClientRegistration;

import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import cep from 'cep-promise';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FiSearch } from 'react-icons/fi';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  Container,
  Content,
  NameInputStyle,
  EmailInputStyle,
  CPFInputStyle,
  CNPJInputStyle,
  Phone1InputStyle,
  Phone2InputStyle,
  Phone3InputStyle,
  NeighborhoodInputStyle,
  CityInputStyle,
  StateInputStyle,
  ComplementInputStyle,
} from './styles';

import Input from '../../components/Input';
import MenuBar from '../../components/MenuBar';

const deleteClientStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #091021',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '600px',
    height: '250px',
  },
  selector: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    background: 'transparent',
    borderRadius: '10px',
    border: '2px solid #091021',
    color: '#091021',
    marginTop: '8px',

    padding: '0 16px',
    width: '230px',
    height: '30px',
  },
  deleteButton: {
    background: '#c53030',
    color: '#ffff',
    border: '2px solid #c53030',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
  },
  cancelButton: {
    background: 'transparent',
    color: '#091021',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
    marginLeft: '400px',
  },
}));

function ClientUpdate() {
  const { id } = useParams();
  const { addToast } = useToast();
  const history = useHistory();
  const clientFormRef = useRef(null);

  const [client, setClient] = useState({
    id: '',
    cpf: '',
    cnpj: '',
    name: '',
    email: '',
    zip_code: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    phone1: '',
    phone2: '',
    phone3: '',
    created_at: '',
    updated_at: '',
    service_orders: [],
  });

  useEffect(() => {
    api.get(`/clients/${id}`).then((response) => {
      const clientData = response.data;
      setClient({
        ...clientData,
        service_orders: [
          ...clientData.normal_service_orders,
          ...clientData.returned_service_orders,
        ],
      });
    });
  }, [client, id]);

  const handleSearchZipCode = () => {
    const zipCodeInput = document.getElementById('zip_code');
    const zipCode = zipCodeInput.value;

    cep(zipCode).then((response) => {
      clientFormRef.current.setFieldValue('address', response.street);
      clientFormRef.current.setFieldValue('state', response.state);
      clientFormRef.current.setFieldValue('city', response.city);
      clientFormRef.current.setFieldValue('neighborhood', response.neighborhood);
    });
  };

  const handleSubmit = useCallback(async (data) => {
    try {
      clientFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        cpf: Yup.string(),
        cnpj: Yup.string(),
        email: Yup.string(),
        zip_code: Yup.string(),
        address: Yup.string(),
        number: Yup.string(),
        complement: Yup.string(),
        neighborhood: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        phone1: Yup.string(),
        phone2: Yup.string(),
        phone3: Yup.string(),
      });

      await schema.validate(data, { abortEarly: false });

      await api.put(`/clients/${id}`, data);

      addToast({
        type: 'success',
        title: 'Cliente atualizado com sucesso',
      });
      history.push('/consulta');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        clientFormRef.current.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao atualizar Cliente',
        description:
          'Ocorreu um erro ao atualizar o cliente. Cheque os dados.',
      });
    }
  }, [addToast, history, id]);

  // DELETE CLIENT MODAL ///////////////////////////////////////////////////////
  const deleteClientClasses = deleteClientStyles();
  const [openDeleteClientModal, setOpenDeleteClientModal] = React.useState(false);
  const deleteClientFormRef = useRef(null);

  const handleDeleteClient = useCallback(async (data) => {
    try {
      clientFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        username: Yup.string()
          .required('Usuário obrigatório'),
        password: Yup.string().required('Senha obrigatória'),
      });

      await schema.validate(data, { abortEarly: false });

      const { username, password } = data;

      await api.delete(`/clients/${id}`, {
        data: {
          confirm_username: username,
          confirm_password: password,
        },
      });

      addToast({
        type: 'success',
        title: 'Cliente deletado com sucesso',
      });

      setOpenDeleteClientModal(false);
      history.replace('/consulta');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        clientFormRef.current.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao deletar Cliente',
        description:
          'Ocorreu um erro ao deletar o Cliente. Cheque suas credenciais.',
      });
    }
  }, [addToast, history, id]);

  const handleOpenDeleteClientModal = () => {
    setOpenDeleteClientModal(true);
  };

  const handleCloseDeleteClientModal = () => {
    setOpenDeleteClientModal(false);
  };

  return (
    <Container>
      <MenuBar />

      <div>
        <Modal
          className={deleteClientClasses.modal}
          open={openDeleteClientModal}
          onClose={handleCloseDeleteClientModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDeleteClientModal}>
            <div className={deleteClientClasses.paper}>
              <h2>
                <b>
                  Deletar Cliente:
                  {' '}
                  {client.name}
                </b>
              </h2>
              <br />

              <div>
                <strong>
                  <b>Confirme login do gerente:</b>
                </strong>
                <Form ref={deleteClientFormRef} onSubmit={handleDeleteClient}>
                  <div className={deleteClientClasses.inputs}>
                    <Input
                      name="username"
                      type="text"
                      placeholder="Usuario"
                    />
                    <Input
                      name="password"
                      type="password"
                      placeholder="Senha"
                    />
                  </div>

                  <button
                    type="submit"
                    className={deleteClientClasses.deleteButton}
                  >
                    Excluir
                  </button>

                  <button
                    onClick={handleCloseDeleteClientModal}
                    type="button"
                    className={deleteClientClasses.cancelButton}
                  >
                    Cancelar
                  </button>
                </Form>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>

      <Content>
        <h1>Editar Cliente</h1>

        <Form ref={clientFormRef} onSubmit={handleSubmit} initialData={client}>
          <section className="client-data">

            <div className="name-email">
              <div className="name">
                <p>Nome Completo</p>
                <Input name="name" type="text" containerStyle={NameInputStyle} />
              </div>
              <div className="email">
                <p>Email</p>
                <Input name="email" type="text" containerStyle={EmailInputStyle} />
              </div>
            </div>

            <div className="cpf-phones">
              <div className="cpf">
                <p>CPF</p>
                <Input name="cpf" type="text" containerStyle={CPFInputStyle} />
              </div>
              <div className="cnpj">
                <p>CNPJ</p>
                <Input name="cnpj" type="text" containerStyle={CNPJInputStyle} />
              </div>
              <div className="phone1">
                <p>Telefone 1</p>
                <Input name="phone1" type="text" containerStyle={Phone1InputStyle} />
              </div>
              <div className="phone2">
                <p>Telefone 2</p>
                <Input name="phone2" type="text" containerStyle={Phone2InputStyle} />
              </div>
              <div className="phone3">
                <p>Telefone 3</p>
                <Input name="phone3" type="text" containerStyle={Phone3InputStyle} />
              </div>
            </div>
          </section>

          <section className="client-address">
            <strong>Endereço</strong>

            <div className="cep-street-number">
              <div className="cep">
                <p>CEP</p>
                <Input
                  id="zip_code"
                  name="zip_code"
                  type="text"
                  containerStyle={
                  {
                    width: '260px',
                    marginRight: '0px',
                  }
                }
                />
              </div>
              <div className="search-zip-code">
                <button type="button" onClick={handleSearchZipCode}>
                  <FiSearch />
                </button>
              </div>
              <div className="street">
                <p>Rua</p>
                <Input
                  name="address"
                  type="text"
                  containerStyle={
                  {
                    width: '440px',
                    marginRight: '16px',
                  }
                }
                />
              </div>
              <div className="number">
                <p>Nº</p>
                <Input
                  name="number"
                  type="text"
                  inputStyle={{
                    width: '56px',
                  }}
                  containerStyle={
                  {
                    width: '70px',
                  }
                }
                />
              </div>
            </div>

            <div className="district-city-state">
              <div className="district">
                <p>Bairro</p>
                <Input name="neighborhood" type="text" containerStyle={NeighborhoodInputStyle} />
              </div>
              <div className="city">
                <p>Cidade</p>
                <Input name="city" type="text" containerStyle={CityInputStyle} />
              </div>
              <div className="state">
                <p>Estado</p>
                <Input name="state" type="text" containerStyle={StateInputStyle} />
              </div>
              <div className="complement">
                <p>Complemento</p>
                <Input name="complement" type="text" containerStyle={ComplementInputStyle} />
              </div>
            </div>
          </section>
          <div className="buttons">
            <button
              className="delete-button"
              type="button"
              onClick={handleOpenDeleteClientModal}
            >
              Excluir Cliente
            </button>
            <button type="submit">Salvar</button>
          </div>
        </Form>

      </Content>
    </Container>
  );
}

export default ClientUpdate;

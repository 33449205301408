import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
import Search from '../pages/Search';
import ListNormalOS from '../pages/List OS Normal';
import ListReturnedOS from '../pages/List OS Retorno';
import ClientRegistration from '../pages/Client Registration';
import NormalOSRegistration from '../pages/Normal OS Registration';
import ReturnedOSRegistration from '../pages/Returned OS Registration';
import ClientUpdate from '../pages/Update Client';
import OS from '../pages/OS';
import Order from '../pages/Order';
import UpdateNormalOS from '../pages/Update Normal OS';
import TodayCashRegister from '../pages/Today Cash Register';
import PeriodCashRegister from '../pages/Period Cash Register';
import UpdateReturnedOS from '../pages/Update Returned OS';
import Configuration from '../pages/Configuration';
import OrderRegistration from '../pages/Order Registration';
import UpdateOrder from '../pages/Update Order';
import ListOrders from '../pages/List Orders';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />

        <Route isPrivate path="/consulta" component={Search} />

        <Route isPrivate path="/listar-os-normal" component={ListNormalOS} />
        <Route isPrivate path="/listar-os-retorno" component={ListReturnedOS} />
        <Route isPrivate path="/listar-pedido" component={ListOrders} />

        <Route isPrivate path="/cadastrar-cliente" component={ClientRegistration} />
        <Route isPrivate path="/editar-cliente/:id" component={ClientUpdate} />

        <Route isPrivate path="/abrir-os-normal" component={NormalOSRegistration} />
        <Route isPrivate path="/abrir-os-retorno" component={ReturnedOSRegistration} />
        <Route isPrivate path="/abrir-pedido" component={OrderRegistration} />

        <Route isPrivate path="/os/:id" component={OS} />
        <Route isPrivate path="/pedido/:id" component={Order} />

        <Route isPrivate path="/editar-os/:id" component={UpdateNormalOS} />
        <Route isPrivate path="/editar-os-retorno/:id" component={UpdateReturnedOS} />
        <Route isPrivate path="/editar-pedido/:id" component={UpdateOrder} />

        <Route isPrivate path="/caixa-hoje" component={TodayCashRegister} />
        <Route isPrivate path="/caixa-periodo" component={PeriodCashRegister} />

        <Route isPrivate path="/configuracoes" component={Configuration} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;

import React, {
  useCallback, useState, useEffect, useMemo, useRef,
} from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate as formatPickerDate,
  parseDate as parsePickerDate,
} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useReactToPrint } from 'react-to-print';
import Fade from '@material-ui/core/Fade';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import formatDate from '../../utils/formatDate';
import formatValue from '../../utils/formatValue';
import getTransactionTypeStringFromInt from '../../utils/getTransactionTypeStringFromInt';
import formatSignedTransactionValue from '../../utils/formatSignedTransactionValue';
import formatTransactionColor from '../../utils/formatTransactionColor';
import isInflowTransaction from '../../utils/isInflowTransaction';
import isOutflowTransaction from '../../utils/isOutflowTransaction';
import isManager from '../../utils/isManager';
import 'moment/locale/pt-br';

import * as printStyle from './printStyle.css';
import headerLogo from '../../assets/headerLogo.png';

import Input from '../../components/Input';
import MenuBar from '../../components/MenuBar';

import {
  Container, Content, PeriodArea, Transactions, Transaction, Buttons,
} from './styles';

// Estilo do ACCESS modal
const accessStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #091021',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '260px',
    height: '220px',
  },
  selector: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  input: {
    background: 'transparent',
    borderRadius: '10px',
    border: '2px solid #091021',
    color: '#091021',
    marginTop: '8px',

    padding: '0 8px',
    width: '222px',
    height: '30px',
    marginBottom: '8px',

  },
  accessButton: {
    background: '#091021',
    color: '#ffff',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
  },
  cancelButton: {
    background: 'transparent',
    color: '#091021',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    marginLeft: '60px',
    padding: '0 16px',
  },
}));

function PeriodCashRegister() {
  const { addToast } = useToast();

  const [hasSearchedAlready, setHasSearchedAlready] = useState(false);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  // Store API response
  const [transactions, setTransactions] = useState([]);

  const componentRef1 = useRef();

  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
    pageStyle: '',
    onAfterPrint: () => handleHidePrint1(),
  });

  const handleShowPrint1 = useCallback(() => {
    const print1 = document.getElementById('print1');

    print1.style.display = '';
  }, []);

  const handleHidePrint1 = useCallback(() => {
    const print1 = document.getElementById('print1');

    print1.style.display = 'none';
  }, []);

  const handleFromChange = useCallback((day) => {
    setFrom(day);
  }, []);

  const handleToChange = useCallback((day) => {
    setTo(day);
  }, []);

  // Modal de ACESSO ////////////////////////////////////////////////////////////////////
  const [isAuthenticatedManager, setIsAuthenticatedManager] = useState(false);
  const [managerToken, setManagerToken] = useState('');
  const accessFormRef = useRef(null);

  const print1 = document.getElementById('print1');

  useEffect(() => {
    handleOpenAccessModal(true);

    if (print1) {
      print1.style.display = 'none';
    }
  }, [print1]);

  const accessClasses = accessStyles();
  const [openAccessModal, setOpenAccessModal] = React.useState(false);

  const handleSearch = useCallback(() => {
    if (managerToken) {
      setHasSearchedAlready(true);
      api.get('/transactions/period', {
        params: {
          start_date: from,
          end_date: to,
        },
        headers: {
          authorization: `Bearer ${managerToken}`,
        },
      }).then((response) => {
        const transactionsData = response.data;

        setTransactions(transactionsData.map((transaction) => ({
          ...transaction,
          formattedType: getTransactionTypeStringFromInt(transaction.type),
          formattedDate: formatDate(transaction.date),
          formattedValue: formatSignedTransactionValue(transaction.type, transaction.value),
          formattedColor: formatTransactionColor(transaction.type),
        })));

        print1.style.display = 'none';
      });
    }
  }, [managerToken, from, to, print1]);

  const periodBalance = useMemo(() => {
    const balance = transactions.reduce((accumulator, currentTransaction) => {
      if (isInflowTransaction(currentTransaction.type)) {
        return accumulator + Number(currentTransaction.value);
      }
      if (isOutflowTransaction(currentTransaction.type)) {
        return accumulator - Number(currentTransaction.value);
      }
      return accumulator;
    }, 0.0);
    return balance;
  }, [transactions]);

  const formattedPeriodBalance = useMemo(() => formatValue(periodBalance), [periodBalance]);

  const handleAccess = useCallback(
    async (data) => {
      try {
        accessFormRef.current.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string()
            .required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post('sessions', data);
        const { user, token } = response.data;

        if (isManager(user.authority_level)) {
          setIsAuthenticatedManager(true);
          setManagerToken(token);
          setOpenAccessModal(false);
        }

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          accessFormRef.current.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Digite credenciais válidas para um(a) gerente',
        });
      }
    },
    [addToast],
  );

  const handleOpenAccessModal = () => {
    setOpenAccessModal(true);
  };

  const handleCloseAccessModal = () => {
    setOpenAccessModal(false);
  };

  return (
    <Container>
      <MenuBar />

      {!isAuthenticatedManager && (
        <h1>Acesso não autorizado</h1>
      )}

      {/* // Modal que de ACESSO ao caixa /////////////////////////////// */}
      <div>
        <Modal
          className={accessClasses.modal}
          open={openAccessModal}
          onClose={handleCloseAccessModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openAccessModal}>
            <div className={accessClasses.paper}>
              <h2>
                <b>Acessar Caixa</b>
              </h2>
              <br />

              <div>
                <strong>
                  <b>Confirme login do gerente:</b>
                </strong>
                <Form ref={accessFormRef} onSubmit={handleAccess}>
                  <div className={accessClasses.inputs}>
                    <Input className={accessClasses.input} name="username" type="text" placeholder="Usuario" />
                    <Input className={accessClasses.input} name="password" type="password" placeholder="Senha" />
                  </div>

                  <button
                    onClick={handleAccess}
                    type="submit"
                    className={accessClasses.accessButton}
                  >
                    Acessar
                  </button>

                  <button
                    onClick={handleCloseAccessModal}
                    type="button"
                    className={accessClasses.cancelButton}
                  >
                    Cancelar
                  </button>
                </Form>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>

      {isAuthenticatedManager && (
        <>
          <Content>
            <h1>Caixa por período</h1>
            <PeriodArea>
              <div className="from-area">
                <p>Do dia:</p>
                <DayPickerInput
                  formatDate={formatPickerDate}
                  parseDate={parsePickerDate}
                  format="L"
                  placeholder={`${formatPickerDate(new Date(), 'L', 'pt-br')}`}
                  dayPickerProps={{
                    locale: 'pt-br',
                    localeUtils: MomentLocaleUtils,
                  }}
                  onDayChange={(day) => handleFromChange(day)}
                />
              </div>
              <div className="to-area">
                <p>Até o dia:</p>
                <DayPickerInput
                  formatDate={formatPickerDate}
                  parseDate={parsePickerDate}
                  format="L"
                  placeholder={`${formatPickerDate(new Date(), 'L', 'pt-br')}`}
                  dayPickerProps={{
                    locale: 'pt-br',
                    localeUtils: MomentLocaleUtils,
                  }}
                  onDayChange={(day) => handleToChange(day)}
                />
              </div>
              <button type="button" onClick={handleSearch}>Buscar</button>
            </PeriodArea>
            <p>
              Balanço:
              {' '}
              {formattedPeriodBalance}
            </p>
            <Transactions>
              {transactions.length > 0 ? transactions.map((transaction) => (
                <Transaction key={transaction.id}>
                  <div className="transaction-data">
                    <p>{transaction.formattedDate}</p>
                    <p>{transaction.formattedType}</p>
                    <p>{transaction.description}</p>
                    <p style={{ color: transaction.formattedColor }}>
                      {transaction.formattedValue}
                    </p>
                    <p>{transaction.payment_method ? transaction.payment_method : '-'}</p>
                    <p>{transaction.service_order ? transaction.service_order.number : '-'}</p>
                    <p>{transaction.service_order ? transaction.service_order.product_name : '-'}</p>
                    <p>{transaction.service_order ? transaction.service_order.product_brand : '-'}</p>
                    <p>{transaction.service_order ? transaction.service_order.client.name : '-'}</p>
                  </div>

                </Transaction>
              )) : (
                <p>
                  {hasSearchedAlready
                    ? 'Não foi encontrada nenhuma transação para esta data.'
                    : 'Selecione a data e clique em Buscar'}
                </p>
              )}
            </Transactions>

            {transactions ? (
              <div ref={componentRef1} id="print1">
                <div className="main">
                  <link type="stylesheet" url={printStyle} />
                  <div className="header">
                    <img src={headerLogo} alt="Logo" />
                    <div className="period-header-right">
                      <h1>
                        Caixa
                      </h1>
                      <h1>
                        {formatPickerDate(new Date(from), 'L', 'pt-br')}
                        {' - '}
                        {formatPickerDate(new Date(to), 'L', 'pt-br')}
                      </h1>
                      <strong>
                        Balanço:
                        {' '}
                        {formattedPeriodBalance}
                      </strong>
                    </div>
                  </div>
                  <div className="transactions">
                    {transactions.length > 0 ? transactions.map((transaction) => (
                      <Transaction key={transaction.id}>
                        <div className="transaction-data">
                          <p>{transaction.formattedDate}</p>
                          <p>{transaction.formattedType}</p>
                          <p>{transaction.description}</p>
                          <p style={{ color: transaction.formattedColor }}>
                            {transaction.formattedValue}
                          </p>
                          <p>{transaction.payment_method ? transaction.payment_method : '-'}</p>
                          <p>{transaction.service_order ? transaction.service_order.number : '-'}</p>
                          <p>{transaction.service_order ? transaction.service_order.product_name : '-'}</p>
                          <p>{transaction.service_order ? transaction.service_order.product_brand : '-'}</p>
                          <p>{transaction.service_order ? transaction.service_order.client.name : '-'}</p>
                        </div>
                      </Transaction>
                    )) : <p>Não foi encontrada nenhuma transação para esta data.</p>}
                  </div>
                </div>
              </div>
            ) : <p />}

            <Buttons>
              <button
                className="print"
                type="button"
                onClick={() => {
                  handleShowPrint1();
                  handlePrint1();
                }}
              >
                Imprimir página

              </button>
            </Buttons>
          </Content>
        </>
      )}
    </Container>
  );
}

export default PeriodCashRegister;

import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import { useToast } from '../../hooks/toast';
import formatUSValue from '../../utils/formatUSValue';
import formatBRValue from '../../utils/formatBRValue';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import OrderStatus from '../../utils/OrderStatus';
import formatStringDate from '../../utils/formatStringDate';
import formatDate from '../../utils/formatDate';

import {
  Container, Content, StatusArea, Client,
} from './styles';

import MenuBar from '../../components/MenuBar';
import Input from '../../components/Input';
import Select from '../../components/Select';

function UpdateOrder() {
  const formRef = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToast();

  const [order, setOrder] = useState({
    id: '',
    number: '',
    status: -1,
    piece_code: '',
    piece_name: '',
    piece_value: '',
    product_model: '',
    created_at: '',
    updated_at: '',
    client: {
      id: '',
      cpf: '',
      name: '',
      email: '',
      zip_code: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      phone1: '',
      phone2: '',
      phone3: '',
      created_at: '',
      updated_at: '',
    },
  });

  useEffect(() => {
    api.get(`/orders/${id}`).then((response) => {
      const orderData = response.data;
      console.log(orderData);

      setOrder(orderData);
    });
    formRef.current.setFieldValue('status', order.status);
  }, [id, order.status]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          piece_name: Yup.string()
            .required('Digite algo para editar'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/orders/${id}`, {
          status: data.status,
          piece_name: data.piece_name,
          piece_code: data.piece_code,
          product_model: data.product_model,
          piece_value: formatUSValue(data.piece_value),
          payment_method: data.payment_method,
          manufacturer_date: data.manufacturer_date ? new Date(formatStringDate(data.manufacturer_date)) : '',
          manufacturer_code: data.manufacturer_code,
        });

        const updatedOrder = response.data;

        history.replace(`/pedido/${updatedOrder.id}`);

        addToast({
          type: 'success',
          title: 'Pedido Editado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          // return;
        }

        addToast({
          type: 'error',
          title: 'Erro na edicão do pedido',
          description:
          'Ocorreu um erro ao editar o pedido. Cheque suas credenciais.',
        });
      }
    },
    [history, addToast, id],
  );

  return (
    <Container>
      <MenuBar />

      <Content>
        <h1>
          Editar Pedido:
          {' '}
          {order.number}
        </h1>

        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            piece_name: order.piece_name,
            piece_code: order.piece_code,
            product_model: order.product_model,
            piece_value: formatBRValue(order.piece_value),
            payment_method: formatBRValue(order.order_payment_method),
            manufacturer_date: order.manufacturer_date ? formatDate(new Date(order.manufacturer_date)) : '',
            manufacturer_code: order.manufacturer_code,
          }}
        >
          <StatusArea>
            <strong>Status: </strong>
            <Select name="status">
              <option value={String(OrderStatus.PENDING)}>
                Aguardando pedido
              </option>
              <option value={String(OrderStatus.CLIENT_NOTIFIED)}>
                Cliente Notificado
              </option>
              <option value={String(OrderStatus.PIECE_DELIVERED_WITHOUT_PAYMENT)}>
                Pedido Entregue Sem Recebimento
              </option>
            </Select>
          </StatusArea>

          <Client>
            <strong>{order.client.name}</strong>
            <p>
              <b>CPF:</b>
              {' '}
              {order.client.cpf || '-'}
            </p>
            <br />
            <p>
              <b>Contato:</b>
            </p>
            <p>
              Telefones:
              {' '}
              {order.client.phone1 || '-'}
              {' '}
              /
              {' '}
              {order.client.phone2 || '-'}
              {' '}
              /
              {' '}
              {order.client.phone3 || '-'}
            </p>
            <br />

            <p>
              <b>Endereço:</b>
            </p>
            <p>
              {' '}
              CEP:
              {' '}
              {order.client.zip_code || '-'}
              {' '}
            </p>
            <p>
              {' '}
              Rua:
              {' '}
              {order.client.address || '-'}
            </p>
            <p>
              {' '}
              Número:
              {' '}
              {order.client.number || '-'}
            </p>
            <p>
              {' '}
              Bairro:
              {' '}
              {order.client.neighborhood || '-'}
            </p>
            <p>
              {' '}
              Complemento:
              {' '}
              {order.client.complement || '-'}
            </p>
          </Client>

          <section className="order-data">
            <strong>Informações do Pedido</strong>

            <div className="name-code-model">
              <div className="name">
                <p>Nome da peça</p>
                <Input
                  type="text"
                  name="piece_name"
                  containerStyle={{
                    width: '350px',
                    marginRight: '16px',
                  }}
                />
              </div>
              <div className="code">
                <p>Código</p>
                <Input
                  type="text"
                  name="piece_code"
                  containerStyle={{
                    width: '300px',
                    marginRight: '16px',
                  }}
                />
              </div>
              <div className="model">
                <p>Modelo do produto</p>
                <Input
                  type="text"
                  name="product_model"
                  inputStyle={{
                    width: '134px',
                  }}
                  containerStyle={{
                    width: '150px',
                    marginRight: '16px',
                  }}
                />
              </div>
            </div>

            <div className="value-method">
              <div className="value">
                <p>Valor</p>
                <Input
                  type="text"
                  name="piece_value"
                  containerStyle={{
                    marginRight: '16px',
                    marginBottom: '24px',
                  }}
                />
              </div>
              <div className="method">
                <p>Forma de Pagamento</p>
                <Input
                  name="payment_method"
                  containerStyle={{
                    width: '200px',
                    marginTop: '8px',
                  }}
                  type="text"
                />
              </div>
            </div>
          </section>

          <section className="manufacturer-data">
            <strong>Informações do Pedido do Fabricante</strong>

            <div className="manufacturer-date-code">
              <div className="manufacturer_date">
                <p>Data do pedido para o fabricante</p>
                <Input
                  type="text"
                  name="manufacturer_date"
                  containerStyle={{
                    width: '250px',
                    marginRight: '16px',
                  }}
                />
              </div>
              <div className="manufacturer_code">
                <p>Código do pedido do fabricante</p>
                <Input
                  type="text"
                  name="manufacturer_code"
                  containerStyle={{
                    width: '300px',
                    marginRight: '16px',
                  }}
                />
              </div>
              <button type="submit">Salvar</button>

            </div>

          </section>

        </Form>

      </Content>
    </Container>
  );
}

export default UpdateOrder;

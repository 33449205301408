import React, {
  useState, useEffect, useRef, useCallback, useMemo,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as printStyle from './printStyle.css';
import getValidationErrors from '../../utils/getValidationErrors';
import formatUSValue from '../../utils/formatUSValue';
import formatDate from '../../utils/formatDate';
import formatCpf from '../../utils/formatCpf';
import formatCnpj from '../../utils/formatCnpj';
import getNormalSOStatusStringFromInt from '../../utils/getNormalSOStatusStringFromInt';
import getReturnedSOStatusStringFromInt from '../../utils/getReturnedSOStatusStringFromInt';
import api from '../../services/api';
import isManager from '../../utils/isManager';
import { useToast } from '../../hooks/toast';

import headerLogo from '../../assets/headerLogo.png';

import {
  Container, Content, NormalOSData, ReturnedOSData, Buttons,
} from './styles';

import MenuBar from '../../components/MenuBar';
import Input from '../../components/Input';
import formatValue from '../../utils/formatValue';

const modalOSStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #091021',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '270px',
    // height: '200px',
  },
  selector: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    background: 'transparent',
    borderRadius: '10px',
    border: '2px solid #091021',
    color: '#091021',

    padding: '0 8px',
    width: '230px',
    height: '30px',
  },
  deleteButton: {
    background: '#c53030',
    color: '#ffff',
    border: '2px solid #c53030',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
  },
  cancelButton: {
    background: 'transparent',
    color: '#091021',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
    marginLeft: '72px',
  },
}));

const createOSTransactionStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #091021',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '350px',
    height: '300px',
  },
  selector: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  input: {
    background: 'transparent',
    borderRadius: '10px',
    border: '2px solid #091021',
    color: '#091021',
    marginTop: '8px',

    padding: '0 8px',
    width: '230px',
    height: '30px',
    marginBottom: '8px',

  },
  createButton: {
    background: '#091021',
    color: '#ffff',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
  },
  cancelButton: {
    background: 'transparent',
    color: '#091021',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    marginLeft: '60px',
    padding: '0 16px',
  },
}));

function OS() {
  const { id } = useParams();
  const componentRef1 = useRef();
  const componentRef2 = useRef();
  const deleteOSRef = useRef(null);
  const cancelOSRef = useRef(null);
  const createOSTransactionFormRef = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [normalServiceOrder, setNormalServiceOrder] = useState({
    id: '',
    number: '',
    type: 0,
    status: -1,
    product_name: '',
    product_model: '',
    product_brand: '',
    product_serial: '',
    product_accessories: '',
    product_details: '',
    fix_description: '',
    fix_technician_name: '',
    fix_date: '',
    fix_value_pieces: '',
    fix_value_labor: '',
    fix_value_final: '',
    client: {
      id: '',
      cpf: '',
      cnpj: '',
      name: '',
      email: '',
      zip_code: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      phone1: '',
      phone2: '',
      phone3: '',
      created_at: '',
      updated_at: '',
    },
    transactions: [],
    created_at: '',
    updated_at: '',
  });

  const [returnedServiceOrder, setReturnedServiceOrder] = useState({
    id: '',
    number: '',
    type: 1,
    status: -1,
    client: '',
    return_motive: '',
    transactions: [],
    fix_description: '',
    fix_technician_name: '',
    fix_date: '',
    fix_value_pieces: '',
    fix_value_labor: '',
    fix_value_final: '',
    original_service_order: {
      id: '',
      number: '',
      type: -1,
      status: -1,
      product_name: '',
      product_model: '',
      product_brand: '',
      product_serial: '',
      product_accessories: '',
      product_details: '',
      fix_description: '',
      fix_technician_name: '',
      fix_date: '',
      fix_value_pieces: '',
      fix_value_labor: '',
      fix_value_final: '',
      client: {
        address: '',
        city: '',
        complement: '',
        cpf: '',
        cnpj: '',
        created_at: '',
        email: '',
        id: '',
        name: '',
        neighborhood: '',
        number: '',
        phone1: '',
        phone2: '',
        phone3: '',
        state: '',
        updated_at: '',
        zip_code: '',
      },
      created_at: '',
      updated_at: '',
    },
    created_at: '',
    updated_at: '',
  });

  const [isNormalServiceOrder, setIsNormalServiceOrder] = useState(true);

  const isFinalized = (serviceOrder) => {
    if (serviceOrder.status === 107 || serviceOrder.status === 108 || serviceOrder.status === 204 || serviceOrder.status === 205) {
      return formatDate(new Date(serviceOrder.created_at));
    }
    return '-';
  };

  const totalUpfrontPayment = useMemo(() => {
    if (isNormalServiceOrder) {
      const balance = normalServiceOrder.transactions
        .reduce((accumulator, currentTransaction) => accumulator + Number(currentTransaction.value), 0.0);
      return balance;
    }

    const balance = returnedServiceOrder.transactions
      .reduce((accumulator, currentTransaction) => accumulator + Number(currentTransaction.value), 0.0);
    return balance;
  }, [normalServiceOrder.transactions, returnedServiceOrder.transactions, isNormalServiceOrder]);

  const osColor = (serviceOrder) => {
    if (isNormalServiceOrder) {
      if (serviceOrder.number[0] === 'M') {
        return '#091021';
      }
      return '#45B052';
    }
    if (returnedServiceOrder.number[1] === 'M') {
      return '#FF9000';
    }
    return '#C1322F';
  };

  useEffect(() => {
    const getServiceOrder = async () => {
      try {
        const responseFromNormal = await api.get(`/service-orders/normal/${id}`);
        const responseNormalServiceOrder = responseFromNormal.data;

        setNormalServiceOrder(responseNormalServiceOrder);
        setIsNormalServiceOrder(true);
      } catch (err) {
        const responseFromReturned = await api.get(`/service-orders/returned/${id}`);
        const responseReturnedServiceOrder = responseFromReturned.data;

        setReturnedServiceOrder(responseReturnedServiceOrder);
        setIsNormalServiceOrder(false);
      }
    };

    getServiceOrder();

    const print1 = document.getElementById('print1');
    const print2 = document.getElementById('print2');

    print1.style.display = 'none';
    print2.style.display = 'none';
  }, [id]);

  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
    pageStyle: '',
    onAfterPrint: () => handleHidePrint1(),
  });

  const handleShowPrint1 = useCallback(() => {
    const print1 = document.getElementById('print1');

    print1.style.display = '';
  }, []);

  const handleHidePrint1 = useCallback(() => {
    const print1 = document.getElementById('print1');

    print1.style.display = 'none';
  }, []);

  const handlePrint2 = useReactToPrint({
    content: () => componentRef2.current,
    pageStyle: '',
    onAfterPrint: () => handleHidePrint2(),
  });

  const handleShowPrint2 = useCallback(() => {
    const print2 = document.getElementById('print2');

    print2.style.display = '';
  }, []);

  const handleHidePrint2 = useCallback(() => {
    const print2 = document.getElementById('print2');

    print2.style.display = 'none';
  }, []);

  // DELETAR OS
  const deleteOSClasses = modalOSStyles();
  const [openDeleteOSModal, setOpenDeleteOSModal] = React.useState(false);
  const [deleteOSModalData, setDeleteOSModalData] = useState();

  const handleDeleteOS = useCallback(async () => {
    if (deleteOSModalData) {
      try {
        deleteOSRef.current.setErrors({});
        const data = deleteOSRef.current.getData();

        const schema = Yup.object().shape({
          username: Yup.string()
            .required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          username, password,
        } = data;

        const authenticationResponse = await api.post('sessions', {
          username,
          password,
        });
        const { user, token } = authenticationResponse.data;

        if (!isManager(user.authority_level)) {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
                'Digite uma credencial de gerente válida',
          });
          return;
        }

        await api.delete(`/service-orders/${deleteOSModalData}`, {
          data: {
            confirm_username: username,
            confirm_password: password,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        setOpenDeleteOSModal(false);

        addToast({
          type: 'success',
          title: 'O.S. deletada com sucesso!',
        });

        history.replace('/consulta');

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          deleteOSRef.current.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao excluir O.S.',
          description:
              'Cheque os valores digitados.',
        });
      }
    }
  }, [addToast, deleteOSModalData, history]);

  const handleOpenDeleteOSModal = (osId) => {
    setDeleteOSModalData(osId);
    setOpenDeleteOSModal(true);
  };
  const handleCloseDeleteOSModal = () => {
    setOpenDeleteOSModal(false);
  };

  // CANCELAR OS
  const cancelOSClasses = modalOSStyles();
  const [openCancelOSModal, setOpenCancelOSModal] = React.useState(false);
  const [cancelOSModalData, setCancelOSModalData] = useState();

  const handleCancelOS = useCallback(async () => {
    if (cancelOSModalData) {
      try {
        cancelOSRef.current.setErrors({});
        const data = cancelOSRef.current.getData();

        const schema = Yup.object().shape({
          cancel_motive: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          cancel_motive,
        } = data;

        await api.post('/transactions/cancel/service-order', {
          service_order_id: cancelOSModalData,
          cancel_motive,
          date: new Date(),
        });

        setOpenCancelOSModal(false);

        addToast({
          type: 'success',
          title: 'O.S. cancelada com sucesso!',
        });

        history.replace('/consulta');

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          cancelOSRef.current.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao cancelar O.S.',
          description:
              'Você pode não ter permissão para cancelar esta O.S.',
        });
      }
    }
  }, [addToast, cancelOSModalData, history]);

  const handleOpenCancelOSModal = (osId) => {
    setCancelOSModalData(osId);
    setOpenCancelOSModal(true);
  };
  const handleCloseCancelOSModal = () => {
    setOpenCancelOSModal(false);
  };

  // ENVIAR OS PARA O CAIXA
  const handleCloseOS = useCallback(async () => {
    try {
      const service_order_id = isNormalServiceOrder
        ? normalServiceOrder.id
        : returnedServiceOrder.id;

      await api.post('/transactions/close/service-order', {
        service_order_id,
        date: new Date(),
        description: '',
      });

      addToast({
        type: 'success',
        title: 'O.S. enviada ao caixa!',
      });

      history.replace('/consulta');

      return;
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao enviar O.S. para o caixa',
        description:
              'Cheque os valores digitados. Para a O.S. ir para o caixa ela deve conter: Valor Final e Forma de Pagamento',
      });
    }
  }, [addToast, history, isNormalServiceOrder, returnedServiceOrder.id, normalServiceOrder.id]);

  // CRIA OS transacoes
  const createOSTransactionClasses = createOSTransactionStyles();
  const [openCreateOSTransactionModal, setOpenCreateOSTransactionModal] = React.useState(false);

  const handleCreateOSTransaction = useCallback(async (data) => {
    try {
      createOSTransactionFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        description: Yup.string()
          .required('Descrição obrigatória'),
        value: Yup.string()
          .required('Valor obrigatório'),
        payment_method: Yup.string()
          .required('Valor obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      const {
        value, description, payment_method,
      } = data;

      if (isNormalServiceOrder) {
        await api.post('/transactions/upfront-payment/service-order', {
          service_order_id: normalServiceOrder.id,
          value: formatUSValue(value),
          description,
          date: new Date(),
          payment_method,
        });
      } else if (!isNormalServiceOrder) {
        await api.post('/transactions/upfront-payment/service-order', {
          service_order_id: returnedServiceOrder.id,
          value: formatUSValue(value),
          description,
          date: new Date(),
          payment_method,
        });
      }

      setOpenCreateOSTransactionModal(false);

      addToast({
        type: 'success',
        title: 'Pagamento antecipado enviado ao caixa!',
      });

      return;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        createOSTransactionFormRef.current.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao criar pagamento antecipado',
        description:
            'Cheque os valores digitados.',
      });
    }
  }, [addToast, normalServiceOrder.id, returnedServiceOrder.id, isNormalServiceOrder]);

  const handleOpenCreateOSTransactionModal = () => {
    setOpenCreateOSTransactionModal(true);
  };

  const handleCloseCreateOSTransactionModal = () => {
    setOpenCreateOSTransactionModal(false);
  };

  return (
    <Container>
      <MenuBar />

      <div>

        <Modal
          className={createOSTransactionClasses.modal}
          open={openCreateOSTransactionModal}
          onClose={handleCloseCreateOSTransactionModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openCreateOSTransactionModal}>
            <div className={createOSTransactionClasses.paper}>
              <h2>
                <b>Criar Pagamento Antecipado</b>
              </h2>
              <br />

              <div>
                <Form ref={createOSTransactionFormRef} onSubmit={handleCreateOSTransaction}>
                  <div>
                    <p>Descrição: </p>
                    <Input name="description" className={createOSTransactionClasses.input} type="text" />
                    <p style={{ marginTop: '8px' }}>Forma de Pagamento: </p>
                    <Input name="payment_method" className={createOSTransactionClasses.input} inputStyle={{ width: '200px' }} type="text" />
                    <p style={{ marginTop: '8px' }}>Valor: </p>
                    <Input name="value" className={createOSTransactionClasses.input} type="text" />
                  </div>

                  <button
                    type="submit"
                    className={createOSTransactionClasses.createButton}
                  >
                    Adicionar ao caixa
                  </button>
                  <button
                    onClick={handleCloseCreateOSTransactionModal}
                    type="button"
                    className={createOSTransactionClasses.cancelButton}
                  >
                    Cancelar
                  </button>
                </Form>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          className={deleteOSClasses.modal}
          open={openDeleteOSModal}
          onClose={handleCloseDeleteOSModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDeleteOSModal}>
            <div className={deleteOSClasses.paper}>
              <h2>
                <b>
                  Deletar O.S.:
                  {' '}
                  {normalServiceOrder.number || returnedServiceOrder.number}
                </b>
              </h2>
              <br />

              <Form ref={deleteOSRef}>
                <strong>
                  <b>Confirme login do gerente:</b>
                </strong>
                <div className={deleteOSClasses.inputs}>
                  <Input name="username" className={deleteOSClasses.input} type="text" placeholder="Usuario" />
                  <Input name="password" className={deleteOSClasses.input} type="password" placeholder="Senha" />
                </div>

                <button
                  onClick={() => handleDeleteOS()}
                  type="button"
                  className={deleteOSClasses.deleteButton}
                >
                  Excluir
                </button>

                <button
                  onClick={handleCloseDeleteOSModal}
                  type="button"
                  className={deleteOSClasses.cancelButton}
                >
                  Voltar
                </button>
              </Form>
            </div>
          </Fade>
        </Modal>

        <Modal
          className={cancelOSClasses.modal}
          open={openCancelOSModal}
          onClose={handleCloseCancelOSModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openCancelOSModal}>
            <div className={cancelOSClasses.paper}>
              <h2>
                <b>
                  Cancelar O.S.:
                  {' '}
                  {normalServiceOrder.number || returnedServiceOrder.number}
                </b>
              </h2>
              <br />

              <Form ref={cancelOSRef}>
                <strong>
                  <b>Motivo do cancelamento:</b>
                </strong>
                <div className={cancelOSClasses.inputs}>
                  <Input name="cancel_motive" className={cancelOSClasses.input} type="text" placeholder="Motivo" />
                </div>
                <br />

                <button
                  onClick={() => handleCancelOS()}
                  type="button"
                  className={cancelOSClasses.deleteButton}
                >
                  Cancelar
                </button>

                <button
                  onClick={handleCloseCancelOSModal}
                  type="button"
                  className={cancelOSClasses.cancelButton}
                >
                  Voltar
                </button>
              </Form>
            </div>
          </Fade>
        </Modal>

      </div>

      <Content>
        <h1>{normalServiceOrder.number || returnedServiceOrder.number || ''}</h1>

        <p>
          <b>Status:</b>
          {' '}
          { isNormalServiceOrder
            ? (getNormalSOStatusStringFromInt(normalServiceOrder.status))
            : (getReturnedSOStatusStringFromInt(returnedServiceOrder.status))}
        </p>
        <div className="edit-delete">
          {normalServiceOrder.status === 107 || returnedServiceOrder.status === 204 || normalServiceOrder.status === 108 || returnedServiceOrder.status === 205 ? (
            <p style={{ visibility: 'hidden', marginRight: '30px' }}>Editar</p>
          ) : (
            <div>
              {isNormalServiceOrder ? (
                <a className="edit" href={`/editar-os/${normalServiceOrder.id}`}>Editar</a>
              ) : (
                <a className="edit" href={`/editar-os-retorno/${returnedServiceOrder.id}`}>Editar</a>
              )}
            </div>
          )}
          <button type="button" onClick={() => (isNormalServiceOrder ? handleOpenDeleteOSModal(normalServiceOrder.id) : handleOpenDeleteOSModal(returnedServiceOrder.id))}>
            <FiTrash2 />
          </button>
        </div>

        { isNormalServiceOrder ? (
          <>
            <NormalOSData>
              <h2><b>Dados do Cliente</b></h2>
              <p>
                <b>Nome:</b>
                {' '}
                {normalServiceOrder.client.name || '-'}
              </p>
              <p>
                <b>CPF:</b>
                {' '}
                {formatCpf(normalServiceOrder.client.cpf) || '-'}
              </p>
              <p>
                <b>CNPJ:</b>
                {' '}
                {formatCnpj(normalServiceOrder.client.cnpj) || '-'}
              </p>
              <br />
              <p>
                <b>Contato:</b>
              </p>
              <p>
                E-mail:
                {' '}
                {normalServiceOrder.client.email || '-'}
              </p>
              <p>
                Telefones:
                {' '}
                {normalServiceOrder.client.phone1 || '-'}
                {' '}
                /
                {' '}
                {normalServiceOrder.client.phone2 || '-'}
                {' '}
                /
                {' '}
                {normalServiceOrder.client.phone3 || '-'}
              </p>
              <br />

              <p>
                <b>Endereço:</b>
              </p>
              <p>
                {' '}
                CEP:
                {' '}
                {normalServiceOrder.client.zip_code || '-'}
                {' '}
              </p>
              <p>
                {' '}
                Rua:
                {' '}
                {normalServiceOrder.client.address || '-'}
              </p>
              <p>
                {' '}
                Número:
                {' '}
                {normalServiceOrder.client.number || '-'}
              </p>
              <p>
                {' '}
                Bairro:
                {' '}
                {normalServiceOrder.client.neighborhood || '-'}
              </p>
              <p>
                {' '}
                Complemento:
                {' '}
                {normalServiceOrder.client.complement || '-'}
              </p>
              <br />

              <h2><b>Dados da O.S.</b></h2>
              <p>
                Número:
                {' '}
                {normalServiceOrder.number || '-'}
              </p>
              <p>
                Status:
                {' '}
                {getNormalSOStatusStringFromInt(normalServiceOrder.status) || '-'}
              </p>
              <p>
                Data de abertura da O.S.:
                {' '}
                {normalServiceOrder.created_at ? formatDate(new Date(normalServiceOrder.created_at)) : '-'}
              </p>
              <p>
                O.S. finalizada em:
                {' '}
                {isFinalized(normalServiceOrder)}
              </p>
              <br />

              <p>
                <b>Produto:</b>
              </p>
              <p>
                Nome:
                {' '}
                {normalServiceOrder.product_name || '-'}
              </p>
              <p>
                Modelo:
                {' '}
                {normalServiceOrder.product_model || '-'}
              </p>
              <p>
                Marca:
                {' '}
                {normalServiceOrder.product_brand || '-'}
              </p>
              <p>
                Defeito Reclamado:
                {' '}
                {normalServiceOrder.product_details || '-'}
              </p>
              <p>
                Acessórios:
                {' '}
                {normalServiceOrder.product_accessories || '-'}
              </p>
              <p>
                Nº de série:
                {' '}
                {normalServiceOrder.product_serial || '-'}
              </p>
              <br />

              <p>
                <b>Conserto:</b>
              </p>
              <p>
                Data do conserto:
                {' '}
                {normalServiceOrder.fix_date ? formatDate(new Date(normalServiceOrder.fix_date)) : '-'}
              </p>
              <p>
                Técnico(a):
                {' '}
                {normalServiceOrder.fix_technician_name || '-'}
              </p>
              <p>
                Peças:
                {' '}
                {(normalServiceOrder.fix_description
                  && normalServiceOrder.fix_description.split('\n').join('; ')
                )
                  || '-'}
              </p>
              <p>
                Valor das peças:
                {' '}
                {formatValue(normalServiceOrder.fix_value_pieces) || '-'}
              </p>
              <p>
                Valor da mão de obra:
                {' '}
                {formatValue(normalServiceOrder.fix_value_labor) || '-'}
              </p>
              <p>
                Valor final:
                {' '}
                {formatValue(normalServiceOrder.fix_value_final) || '-'}
              </p>

              <br />

              <p>
                Forma de pagamento:
                {' '}
                {normalServiceOrder.fix_payment_method || '-'}
              </p>

              <br />

              <p>
                <b>Pagamentos:</b>
              </p>

              {normalServiceOrder.transactions.length > 0 ? (
                <>
                  {normalServiceOrder.transactions.map((transaction) => (
                    <>
                      <p>
                        Valor:
                        {' '}
                        {formatValue(transaction.value)}
                      </p>
                      <p>
                        Descrição:
                        {' '}
                        {transaction.description}
                      </p>
                      <p>
                        Data:
                        {' '}
                        {formatDate(new Date(transaction.created_at))}
                      </p>
                      <br />
                    </>
                  ))}
                </>
              ) : 'Sem pagamentos'}

            </NormalOSData>

            <div ref={componentRef1} id="print1">
              <div className="main">
                <link type="stylesheet" url={printStyle} />
                <div className="header">
                  <img src={headerLogo} alt="Logo" />
                  <div className="header-right">
                    <p>{normalServiceOrder.created_at ? formatDate(new Date(normalServiceOrder.created_at)) : '-'}</p>
                    <h1 style={{ color: osColor(normalServiceOrder) }}>
                      <b>{normalServiceOrder.number}</b>
                    </h1>
                    <p>
                      Não é documento fiscal
                    </p>
                  </div>
                </div>
                <div className="client-data">
                  <h3><b>{normalServiceOrder.client.name}</b></h3>
                  <div className="data1">
                    <div className="cpf-phones">
                      <strong>
                        CPF:
                        {' '}
                        {formatCpf(normalServiceOrder.client.cpf)}
                      </strong>
                      <br />
                      <strong>
                        CNPJ:
                        {' '}
                        {formatCnpj(normalServiceOrder.client.cnpj)}
                      </strong>
                      <p>Telefones: </p>
                      <p>{normalServiceOrder.client.phone1}</p>
                      <p>{normalServiceOrder.client.phone2}</p>
                      <p>{normalServiceOrder.client.phone3}</p>
                    </div>
                    <div className="address">
                      <strong>
                        Endereco:
                      </strong>
                      <p>
                        CEP:
                        {' '}
                        {normalServiceOrder.client.zip_code}
                      </p>
                      <p>
                        Rua:
                        {' '}
                        {normalServiceOrder.client.address}
                        ,
                        {' '}
                        {normalServiceOrder.client.number}
                      </p>
                      <p>
                        Bairo:
                        {' '}
                        {normalServiceOrder.client.neighborhood}
                      </p>
                      <p>
                        Cidade:
                        {' '}
                        {normalServiceOrder.client.city}
                        ,
                        {' '}
                        {normalServiceOrder.client.state}
                      </p>
                    </div>
                    <div className="complement">
                      <br />
                      <p>
                        Complemento:
                        {' '}
                        {normalServiceOrder.client.complement}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-data">
                  <h3><b>Aparelho</b></h3>
                  <div className="data2">
                    <div className="product">
                      <p>
                        Nome:
                        {' '}
                        {normalServiceOrder.product_name}
                      </p>
                      <p>
                        Modelo:
                        {' '}
                        {normalServiceOrder.product_model}
                      </p>
                      <p>
                        Marca:
                        {' '}
                        {normalServiceOrder.product_brand}
                      </p>
                      <p>
                        Nº de série:
                        {' '}
                        {normalServiceOrder.product_serial}
                      </p>
                    </div>
                    <div className="accessories">
                      <p>
                        Acessórios:
                        {' '}
                        {normalServiceOrder.product_accessories}
                      </p>
                    </div>
                    <div className="details">
                      <p>
                        Defeito Reclamado:
                        {' '}
                        {normalServiceOrder.product_details}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fix-data">
                  <h3><b>Conserto</b></h3>
                  <div className="data3">
                    <div className="fix">
                      <p>
                        Data do conserto:
                        {' '}
                        {normalServiceOrder.fix_date ? formatDate(new Date(normalServiceOrder.fix_date)) : '-'}
                      </p>
                      <p>
                        Técnico:
                        {' '}
                        {normalServiceOrder.fix_technician_name}
                      </p>
                      <p>
                        Valor das peças:
                        {' '}
                        {formatValue(normalServiceOrder.fix_value_pieces)}
                      </p>
                      <p>
                        Valor da mão de obra:
                        {' '}
                        {formatValue(normalServiceOrder.fix_value_labor)}
                      </p>
                      <p>
                        Pagamento(s):
                        {' '}
                        {formatValue(totalUpfrontPayment)}
                      </p>
                    </div>
                    <div className="description">
                      <p>
                        {(normalServiceOrder.fix_description
                          && normalServiceOrder.fix_description.split('\n').join('; ')
                        )
                          || '-'}

                      </p>
                    </div>
                    <div className="final">
                      <h3>
                        Valor total:
                        {' '}
                        {formatValue(normalServiceOrder.fix_value_final)}
                      </h3>
                      <p>
                        Forma de pagamento:
                        {' '}
                        {normalServiceOrder.fix_payment_method || '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <p>OBS.: Garantia do serviço executado valida por 90 dias mediante a apresentacao desta O.S.</p>
                </div>
                <div className="ticket-area">
                  <div className="ticket">
                    <h3 style={{ color: osColor(normalServiceOrder) }}>{normalServiceOrder.number}</h3>
                    <p>{normalServiceOrder.client.name}</p>
                    <p>{normalServiceOrder.product_name}</p>
                    <p>{normalServiceOrder.product_brand}</p>
                    <p>{normalServiceOrder.product_serial}</p>
                  </div>
                  <div className="ticket">
                    <h3 style={{ color: osColor(normalServiceOrder) }}>{normalServiceOrder.number}</h3>
                    <p>{normalServiceOrder.client.name}</p>
                    <p>{normalServiceOrder.product_name}</p>
                    <p>{normalServiceOrder.product_brand}</p>
                    <p>{normalServiceOrder.product_serial}</p>
                  </div>
                  <div className="ticket">
                    <h3 style={{ color: osColor(normalServiceOrder) }}>{normalServiceOrder.number}</h3>
                    <p>{normalServiceOrder.client.name}</p>
                    <p>{normalServiceOrder.product_name}</p>
                    <p>{normalServiceOrder.product_brand}</p>
                    <p>{normalServiceOrder.product_serial}</p>
                  </div>
                  <div className="ticket">
                    <h3 style={{ color: osColor(normalServiceOrder) }}>{normalServiceOrder.number}</h3>
                    <p>{normalServiceOrder.client.name}</p>
                    <p>{normalServiceOrder.product_name}</p>
                    <p>{normalServiceOrder.product_brand}</p>
                    <p>{normalServiceOrder.product_serial}</p>
                  </div>
                </div>
              </div>
            </div>

            <div ref={componentRef2} id="print2">
              <div className="main">
                <link type="stylesheet" url={printStyle} />
                <div className="header">
                  <img src={headerLogo} alt="Logo" />
                  <div className="header-right">
                    <p>Ficha para entrega</p>
                    <h1>
                      <b>{normalServiceOrder.number}</b>
                    </h1>
                    <p>
                      Não é documento fiscal
                    </p>
                  </div>
                </div>
                <div className="client-data">
                  <h3><b>{normalServiceOrder.client.name}</b></h3>
                  <div className="data1">
                    <div className="cpf-phones">
                      <strong>
                        CPF:
                        {' '}
                        {formatCpf(normalServiceOrder.client.cpf)}
                      </strong>
                      <br />
                      <strong>
                        CNPJ:
                        {' '}
                        {formatCnpj(normalServiceOrder.client.cnpj)}
                      </strong>
                      <p>Telefones: </p>
                      <p>{normalServiceOrder.client.phone1}</p>
                      <p>{normalServiceOrder.client.phone2}</p>
                      <p>{normalServiceOrder.client.phone3}</p>
                    </div>
                    <div className="address">
                      <strong>
                        Endereco:
                      </strong>
                      <p>
                        CEP:
                        {' '}
                        {normalServiceOrder.client.zip_code}
                      </p>
                      <p>
                        Rua:
                        {' '}
                        {normalServiceOrder.client.address}
                        ,
                        {' '}
                        {normalServiceOrder.client.number}
                      </p>
                      <p>
                        Bairo:
                        {' '}
                        {normalServiceOrder.client.neighborhood}
                      </p>
                      <p>
                        Cidade:
                        {' '}
                        {normalServiceOrder.client.city}
                        ,
                        {' '}
                        {normalServiceOrder.client.state}
                      </p>
                    </div>
                    <div className="complement">
                      <br />
                      <p>
                        Complemento:
                        {' '}
                        {normalServiceOrder.client.complement}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-data">
                  <h3><b>Aparelho</b></h3>
                  <div className="data2">
                    <div className="product">
                      <p>
                        Nome:
                        {' '}
                        {normalServiceOrder.product_name}
                      </p>
                      <p>
                        Modelo:
                        {' '}
                        {normalServiceOrder.product_model}
                      </p>
                      <p>
                        Marca:
                        {' '}
                        {normalServiceOrder.product_brand}
                      </p>
                      <p>
                        Nº de série:
                        {' '}
                        {normalServiceOrder.product_serial}
                      </p>
                    </div>
                    <div className="accessories">
                      <p>
                        Acessórios:
                        {' '}
                        {normalServiceOrder.product_accessories}
                      </p>
                    </div>
                    <div className="details">
                      <p>
                        Defeito Reclamado:
                        {' '}
                        {normalServiceOrder.product_details}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fix-data">
                  <h3><b>Conserto</b></h3>
                  <div className="data3">
                    <div className="fix">
                      <p>
                        Data do conserto:
                        {' '}
                        {normalServiceOrder.fix_date ? formatDate(new Date(normalServiceOrder.fix_date)) : '-'}
                      </p>
                      <p>
                        Técnico:
                        {' '}
                        {normalServiceOrder.fix_technician_name}
                      </p>
                      <p>
                        Valor das peças:
                        {' '}
                        {formatValue(normalServiceOrder.fix_value_pieces)}
                      </p>
                      <p>
                        Valor da mão de obra:
                        {' '}
                        {formatValue(normalServiceOrder.fix_value_labor)}
                      </p>
                      <p>
                        Pagamento(s):
                        {' '}
                        {formatValue(totalUpfrontPayment)}
                      </p>
                    </div>
                    <div className="description">
                      <p>
                        {(normalServiceOrder.fix_description
                          && normalServiceOrder.fix_description.split('\n').join('; ')
                        )
                          || '-'}
                      </p>
                    </div>
                    <div className="final">
                      <h3>
                        Valor total:
                        {' '}
                        {formatValue(normalServiceOrder.fix_value_final)}
                      </h3>
                      <p>
                        Forma de pagamento:
                        {' '}
                        {normalServiceOrder.fix_payment_method || '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <p>
                    Garantia do serviço executado valida por 90 dias mediante a apresentacao desta O.S.
                  </p>
                </div>
                <br />
                <div className="header">
                  <img src={headerLogo} alt="Logo" />
                  <div className="header-right">
                    <p>Ficha para entrega</p>
                    <h1>
                      <b>{normalServiceOrder.number}</b>
                    </h1>
                    <p>
                      Não é documento fiscal
                    </p>
                  </div>
                </div>
                <div className="client-data">
                  <h3><b>{normalServiceOrder.client.name}</b></h3>
                  <div className="data1">
                    <div className="cpf-phones">
                      <strong>
                        CPF:
                        {' '}
                        {formatCpf(normalServiceOrder.client.cpf)}
                      </strong>
                      <br />
                      <strong>
                        CNPJ:
                        {' '}
                        {formatCnpj(normalServiceOrder.client.cnpj)}
                      </strong>
                      <p>Telefones: </p>
                      <p>{normalServiceOrder.client.phone1}</p>
                      <p>{normalServiceOrder.client.phone2}</p>
                      <p>{normalServiceOrder.client.phone3}</p>
                    </div>
                    <div className="address">
                      <strong>
                        Endereco:
                      </strong>
                      <p>
                        CEP:
                        {' '}
                        {normalServiceOrder.client.zip_code}
                      </p>
                      <p>
                        Rua:
                        {' '}
                        {normalServiceOrder.client.address}
                        ,
                        {' '}
                        {normalServiceOrder.client.number}
                      </p>
                      <p>
                        Bairo:
                        {' '}
                        {normalServiceOrder.client.neighborhood}
                      </p>
                      <p>
                        Cidade:
                        {' '}
                        {normalServiceOrder.client.city}
                        ,
                        {' '}
                        {normalServiceOrder.client.state}
                      </p>
                    </div>
                    <div className="complement">
                      <br />
                      <p>
                        Complemento:
                        {' '}
                        {normalServiceOrder.client.complement}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-data">
                  <h3><b>Aparelho</b></h3>
                  <div className="data2">
                    <div className="product">
                      <p>
                        Nome:
                        {' '}
                        {normalServiceOrder.product_name}
                      </p>
                      <p>
                        Modelo:
                        {' '}
                        {normalServiceOrder.product_model}
                      </p>
                      <p>
                        Marca:
                        {' '}
                        {normalServiceOrder.product_brand}
                      </p>
                      <p>
                        Nº de série:
                        {' '}
                        {normalServiceOrder.product_serial}
                      </p>
                    </div>
                    <div className="accessories">
                      <p>
                        Acessórios:
                        {' '}
                        {normalServiceOrder.product_accessories}
                      </p>
                    </div>
                    <div className="details">
                      <p>
                        Defeito Reclamado:
                        {' '}
                        {normalServiceOrder.product_details}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fix-data">
                  <h3><b>Conserto</b></h3>
                  <div className="data3">

                    <div className="fix">
                      <p>
                        Data do conserto:
                        {' '}
                        {normalServiceOrder.fix_date ? formatDate(new Date(normalServiceOrder.fix_date)) : '-'}
                      </p>
                      <p>
                        Técnico:
                        {' '}
                        {normalServiceOrder.fix_technician_name}
                      </p>
                      <p>
                        Valor das peças:
                        {' '}
                        {formatValue(normalServiceOrder.fix_value_pieces)}
                      </p>
                      <p>
                        Valor da mão de obra:
                        {' '}
                        {formatValue(normalServiceOrder.fix_value_labor)}
                      </p>
                      <p>
                        Pagamento(s):
                        {' '}
                        {formatValue(totalUpfrontPayment)}
                      </p>
                    </div>
                    <div className="description">
                      <p>
                        {(normalServiceOrder.fix_description
                        && normalServiceOrder.fix_description.split('\n').join('; ')
                        )
                        || '-'}
                      </p>
                    </div>
                    <div className="final">
                      <h3>
                        Valor total:
                        {' '}
                        {formatValue(normalServiceOrder.fix_value_final)}
                      </h3>
                      <p>
                        Forma de pagamento:
                        {' '}
                        {normalServiceOrder.fix_payment_method || '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <p>Garantia do serviço executado valida por 90 dias mediante a apresentacao desta O.S.</p>
                </div>
              </div>
            </div>

          </>
        ) : (
          <>
            <ReturnedOSData>
              <h2><b>Dados do Cliente</b></h2>
              <p>
                <b>Nome:</b>
                {' '}
                {returnedServiceOrder.original_service_order.client.name || '-'}
              </p>
              <p>
                <b>CPF:</b>
                {' '}
                {formatCpf(returnedServiceOrder.original_service_order.client.cpf) || '-'}
              </p>
              <p>
                <b>CNPJ:</b>
                {' '}
                {formatCnpj(returnedServiceOrder.original_service_order.client.cnpj) || '-'}
              </p>
              <br />
              <p>
                <b>Contato:</b>
              </p>
              <p>
                E-mail:
                {' '}
                {returnedServiceOrder.original_service_order.client.email || '-'}
              </p>
              <p>
                Telefones:
                {' '}
                {returnedServiceOrder.original_service_order.client.phone1 || '-'}
                {' '}
                /
                {' '}
                {returnedServiceOrder.original_service_order.client.phone2 || '-'}
                {' '}
                /
                {' '}
                {returnedServiceOrder.original_service_order.client.phone3 || '-'}
              </p>
              <br />

              <p>
                <b>Endereço:</b>
              </p>
              <p>
                {' '}
                CEP:
                {' '}
                {returnedServiceOrder.original_service_order.client.zip_code || '-'}
                {' '}
              </p>
              <p>
                {' '}
                Rua:
                {' '}
                {returnedServiceOrder.original_service_order.client.address || '-'}
              </p>
              <p>
                {' '}
                Número:
                {' '}
                {returnedServiceOrder.original_service_order.client.number || '-'}
              </p>
              <p>
                {' '}
                Bairro:
                {' '}
                {returnedServiceOrder.original_service_order.client.neighborhood || '-'}
              </p>
              <p>
                {' '}
                Complemento:
                {' '}
                {returnedServiceOrder.original_service_order.client.complement || '-'}
              </p>
              <br />

              <h2><b>Dados da O.S. Original</b></h2>
              <p>
                Número:
                {' '}
                {returnedServiceOrder.original_service_order.number || '-'}
              </p>
              <p>
                Status:
                {' '}
                {getNormalSOStatusStringFromInt(returnedServiceOrder.original_service_order.status) || '-'}
              </p>
              <p>
                Data de abertura da O.S.:
                {' '}
                {returnedServiceOrder.original_service_order.created_at ? formatDate(new Date(returnedServiceOrder.original_service_order.created_at)) : '-'}
              </p>
              <p>
                O.S. finalizada em:
                {' '}
                {isFinalized(returnedServiceOrder.original_service_order)}
              </p>
              <br />

              <p>
                <b>Produto:</b>
              </p>
              <p>
                Nome:
                {' '}
                {returnedServiceOrder.original_service_order.product_name || '-'}
              </p>
              <p>
                Modelo:
                {' '}
                {returnedServiceOrder.original_service_order.product_model || '-'}
              </p>
              <p>
                Marca:
                {' '}
                {returnedServiceOrder.original_service_order.product_brand || '-'}
              </p>
              <p>
                Defeito Reclamado:
                {' '}
                {returnedServiceOrder.original_service_order.product_details || '-'}
              </p>
              <p>
                Acessórios:
                {' '}
                {returnedServiceOrder.original_service_order.product_accessories || '-'}
              </p>
              <p>
                Nº de série:
                {' '}
                {returnedServiceOrder.original_service_order.product_serial || '-'}
              </p>
              <br />

              <p>
                <b>Conserto:</b>
              </p>
              <p>
                Data do conserto:
                {' '}
                {returnedServiceOrder.original_service_order.fix_date ? formatDate(new Date(returnedServiceOrder.original_service_order.fix_date)) : '-'}
              </p>
              <p>
                Técnico(a):
                {' '}
                {returnedServiceOrder.original_service_order.fix_technician_name || '-'}
              </p>
              <p>
                Peças:
                {' '}
                {(returnedServiceOrder.original_service_order.fix_description
                  && returnedServiceOrder.original_service_order.fix_description.split('\n')
                    .join('; ')
                )
                  || '-'}
              </p>
              <p>
                Valor das peças:
                {' '}
                {formatValue(returnedServiceOrder.original_service_order.fix_value_pieces) || '-'}
              </p>
              <p>
                Valor da mão de obra:
                {' '}
                {formatValue(returnedServiceOrder.original_service_order.fix_value_labor) || '-'}
              </p>
              <p>
                Valor final:
                {' '}
                {formatValue(returnedServiceOrder.original_service_order.fix_value_final) || '-'}
              </p>
              <p>
                Forma de pagamento:
                {' '}
                {returnedServiceOrder.original_service_order.fix_payment_method || '-'}
              </p>
              <br />

              <h2><b>Retorno</b></h2>
              <p>
                Número:
                {' '}
                {returnedServiceOrder.number || '-'}
              </p>
              <p>
                Status:
                {' '}
                {getReturnedSOStatusStringFromInt(returnedServiceOrder.status) || '-'}
              </p>
              <p>
                Motivo do Retorno:
                {' '}
                {returnedServiceOrder.return_motive || '-'}
              </p>
              <p>
                Data de abertura da O.S. de Retorno:
                {' '}
                {returnedServiceOrder.created_at ? formatDate(new Date(returnedServiceOrder.created_at)) : '-'}
              </p>
              <p>
                O.S. de Retorno finalizada em:
                {' '}
                {isFinalized(returnedServiceOrder)}
              </p>
              <br />
              <p>
                <b>Conserto Retorno:</b>
              </p>
              <p>
                Data do conserto:
                {' '}
                {returnedServiceOrder.fix_date ? formatDate(new Date(returnedServiceOrder.fix_date)) : '-'}
              </p>
              <p>
                Técnico(a):
                {' '}
                {returnedServiceOrder.fix_technician_name || '-'}
              </p>
              <p>
                Descrição do conserto:
                {' '}
                {returnedServiceOrder.fix_description || '-'}
              </p>
              <p>
                Valor das peças:
                {' '}
                {formatValue(returnedServiceOrder.fix_value_pieces) || '-'}
              </p>
              <p>
                Valor da mão de obra:
                {' '}
                {formatValue(returnedServiceOrder.fix_value_labor) || '-'}
              </p>
              <p>
                Valor final:
                {' '}
                {formatValue(returnedServiceOrder.fix_value_final) || '-'}
              </p>
              <p>
                Forma de pagamento:
                {' '}
                {returnedServiceOrder.fix_payment_method || '-'}
              </p>
              <br />

              <p>
                <b>Pagamentos:</b>
              </p>

              {returnedServiceOrder.transactions.length > 0 ? (
                <>
                  {returnedServiceOrder.transactions.map((transaction) => (
                    <>
                      <p>
                        Valor:
                        {' '}
                        {formatValue(transaction.value)}
                      </p>
                      <p>
                        Descrição:
                        {' '}
                        {transaction.description}
                      </p>
                      <p>
                        Data:
                        {' '}
                        {formatDate(new Date(transaction.created_at))}
                      </p>
                      <br />
                    </>
                  ))}
                </>
              ) : 'Sem pagamentos'}
            </ReturnedOSData>

            <div ref={componentRef1} id="print1">
              <div className="main">
                <link type="stylesheet" url={printStyle} />
                <div className="header">
                  <img src={headerLogo} alt="Logo" />
                  <div className="header-right">
                    {returnedServiceOrder.created_at ? formatDate(new Date(returnedServiceOrder.created_at)) : '-'}
                    <h1 style={{ color: osColor(normalServiceOrder) }}>
                      <b>{returnedServiceOrder.number}</b>
                    </h1>
                    <p>
                      Não é documento fiscal
                    </p>
                  </div>
                </div>
                <div className="client-data">
                  <h3><b>{returnedServiceOrder.original_service_order.client.name}</b></h3>
                  <div className="data1">
                    <div className="cpf-phones">
                      <strong>
                        CPF:
                        {' '}
                        {formatCpf(returnedServiceOrder.original_service_order.client.cpf)}
                      </strong>
                      <br />
                      <strong>
                        CNPJ:
                        {' '}
                        {formatCnpj(returnedServiceOrder.original_service_order.client.cnpj)}
                      </strong>
                      <p>Telefones: </p>
                      <p>{returnedServiceOrder.original_service_order.client.phone1}</p>
                      <p>{returnedServiceOrder.original_service_order.client.phone2}</p>
                      <p>{returnedServiceOrder.original_service_order.client.phone3}</p>
                    </div>
                    <div className="address">
                      <strong>
                        Endereco:
                      </strong>
                      <p>
                        CEP:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.zip_code}
                      </p>
                      <p>
                        Rua:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.address}
                        ,
                        {' '}
                        {returnedServiceOrder.original_service_order.client.number}
                      </p>
                      <p>
                        Bairo:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.neighborhood}
                      </p>
                      <p>
                        Cidade:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.city}
                        ,
                        {' '}
                        {returnedServiceOrder.original_service_order.client.state}
                      </p>
                    </div>
                    <div className="complement">
                      <br />
                      <p>
                        Complemento:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.complement}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-data">
                  <h3><b>Retorno</b></h3>
                  <div className="data2">
                    <div className="product">
                      <p>
                        Aparelho:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_name}
                      </p>
                      <p>
                        Modelo:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_model}
                      </p>
                      <p>
                        Marca:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_brand}
                      </p>
                      <p>
                        Nº de série:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_serial}
                      </p>
                    </div>
                    <div className="accessories">
                      <p>
                        Acessórios:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_accessories}
                      </p>
                    </div>
                    <div className="details">
                      <p>
                        Motivo do Retorno:
                        {' '}
                        {returnedServiceOrder.return_motive}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fix-data">
                  <h3><b>Conserto Retorno</b></h3>
                  <div className="data3">

                    <div className="fix">
                      <p>
                        Data do conserto:
                        {' '}
                        {returnedServiceOrder.fix_date ? formatDate(new Date(returnedServiceOrder.fix_date)) : '-'}
                      </p>
                      <p>
                        Técnico:
                        {' '}
                        {returnedServiceOrder.fix_technician_name}
                      </p>
                      <p>
                        Valor das peças:
                        {' '}
                        {formatValue(returnedServiceOrder.fix_value_pieces)}
                      </p>
                      <p>
                        Valor da mão de obra:
                        {' '}
                        {formatValue(returnedServiceOrder.fix_value_labor)}
                      </p>
                      <p>
                        Pagamento(s):
                        {' '}
                        {formatValue(totalUpfrontPayment)}
                      </p>
                    </div>
                    <div className="description">
                      <p>
                        {(returnedServiceOrder.fix_description
                          && returnedServiceOrder.fix_description.split('\n')
                            .join('; ')
                        )}
                      </p>
                    </div>
                    <div className="final">
                      <h3>
                        Valor total:
                        {' '}
                        {formatValue(returnedServiceOrder.fix_value_final)}
                      </h3>
                      <p>
                        Forma de pagamento:
                        {' '}
                        {returnedServiceOrder.fix_payment_method || '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <p>OBS.: Garantia do serviço executado valida por 90 dias mediante a apresentacao desta O.S.</p>
                </div>
                <div className="ticket-area">
                  <div className="ticket">
                    <h3 style={{ color: osColor(normalServiceOrder) }}>{returnedServiceOrder.number}</h3>
                    <p>{returnedServiceOrder.original_service_order.client.name}</p>
                    <p>{returnedServiceOrder.original_service_order.product_name}</p>
                    <p>{returnedServiceOrder.original_service_order.product_brand}</p>
                    <p>{returnedServiceOrder.original_service_order.product_serial}</p>
                  </div>
                  <div className="ticket">
                    <h3 style={{ color: osColor(normalServiceOrder) }}>{returnedServiceOrder.number}</h3>
                    <p>{returnedServiceOrder.original_service_order.client.name}</p>
                    <p>{returnedServiceOrder.original_service_order.product_name}</p>
                    <p>{returnedServiceOrder.original_service_order.product_brand}</p>
                    <p>{returnedServiceOrder.original_service_order.product_serial}</p>
                  </div>
                  <div className="ticket">
                    <h3 style={{ color: osColor(normalServiceOrder) }}>{returnedServiceOrder.number}</h3>
                    <p>{returnedServiceOrder.original_service_order.client.name}</p>
                    <p>{returnedServiceOrder.original_service_order.product_name}</p>
                    <p>{returnedServiceOrder.original_service_order.product_brand}</p>
                    <p>{returnedServiceOrder.original_service_order.product_serial}</p>
                  </div>
                  <div className="ticket">
                    <h3 style={{ color: osColor(normalServiceOrder) }}>{returnedServiceOrder.number}</h3>
                    <p>{returnedServiceOrder.original_service_order.client.name}</p>
                    <p>{returnedServiceOrder.original_service_order.product_name}</p>
                    <p>{returnedServiceOrder.original_service_order.product_brand}</p>
                    <p>{returnedServiceOrder.original_service_order.product_serial}</p>
                  </div>
                </div>
              </div>
            </div>

            <div ref={componentRef2} id="print2">
              <div className="main">
                <link type="stylesheet" url={printStyle} />
                <div className="header">
                  <img src={headerLogo} alt="Logo" />
                  <div className="header-right">
                    <p>Ficha para entrega</p>
                    <h1>
                      <b>{returnedServiceOrder.number}</b>
                    </h1>
                    <p>
                      Não é documento fiscal
                    </p>
                  </div>
                </div>
                <div className="client-data">
                  <h3><b>{returnedServiceOrder.original_service_order.client.name}</b></h3>
                  <div className="data1">
                    <div className="cpf-phones">
                      <strong>
                        CPF:
                        {' '}
                        {formatCpf(returnedServiceOrder.original_service_order.client.cpf)}
                      </strong>
                      <br />
                      <strong>
                        CNPJ:
                        {' '}
                        {formatCnpj(returnedServiceOrder.original_service_order.client.cnpj)}
                      </strong>
                      <p>Telefones: </p>
                      <p>{returnedServiceOrder.original_service_order.client.phone1}</p>
                      <p>{returnedServiceOrder.original_service_order.client.phone2}</p>
                      <p>{returnedServiceOrder.original_service_order.client.phone3}</p>
                    </div>
                    <div className="address">
                      <strong>
                        Endereco:
                      </strong>
                      <p>
                        CEP:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.zip_code}
                      </p>
                      <p>
                        Rua:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.address}
                        ,
                        {' '}
                        {returnedServiceOrder.original_service_order.client.number}
                      </p>
                      <p>
                        Bairo:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.neighborhood}
                      </p>
                      <p>
                        Cidade:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.city}
                        ,
                        {' '}
                        {returnedServiceOrder.original_service_order.client.state}
                      </p>
                    </div>
                    <div className="complement">
                      <br />
                      <p>
                        Complemento:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.complement}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-data">
                  <h3><b>Retorno</b></h3>
                  <div className="data2">
                    <div className="product">
                      <p>
                        Aparelho:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_name}
                      </p>
                      <p>
                        Modelo:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_model}
                      </p>
                      <p>
                        Marca:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_brand}
                      </p>
                      <p>
                        Nº de série:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_serial}
                      </p>
                    </div>
                    <div className="accessories">
                      <p>
                        Acessórios:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_accessories}
                      </p>
                    </div>
                    <div className="details">
                      <p>
                        Motivo do Retorno:
                        {' '}
                        {returnedServiceOrder.return_motive}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fix-data">
                  <h3><b>Conserto Retorno</b></h3>
                  <div className="data3">
                    <div className="fix">
                      <p>
                        Data do conserto:
                        {' '}
                        {returnedServiceOrder.fix_date ? formatDate(new Date(returnedServiceOrder.fix_date)) : '-'}
                      </p>
                      <p>
                        Técnico:
                        {' '}
                        {returnedServiceOrder.fix_technician_name}
                      </p>
                      <p>
                        Valor das peças:
                        {' '}
                        {formatValue(returnedServiceOrder.fix_value_pieces)}
                      </p>
                      <p>
                        Valor da mão de obra:
                        {' '}
                        {formatValue(returnedServiceOrder.fix_value_labor)}
                      </p>
                      <p>
                        Pagamento(s):
                        {' '}
                        {formatValue(totalUpfrontPayment)}
                      </p>
                    </div>
                    <div className="description">
                      <p>
                        {(returnedServiceOrder.fix_description
                          && returnedServiceOrder.fix_description.split('\n')
                            .join('; ')
                        )}
                      </p>
                    </div>
                    <div className="final">
                      <h3>
                        Valor total:
                        {' '}
                        {formatValue(returnedServiceOrder.fix_value_final)}
                      </h3>
                      <p>
                        Forma de pagamento:
                        {' '}
                        {returnedServiceOrder.fix_payment_method || '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <p>OBS.: Garantia do serviço executado valida por 90 dias mediante a apresentacao desta O.S.</p>
                </div>
                <br />
                <div className="header">
                  <img src={headerLogo} alt="Logo" />
                  <div className="header-right">
                    <p>Ficha para entrega</p>
                    <h1>
                      <b>{returnedServiceOrder.number}</b>
                    </h1>
                    <p>
                      Não é documento fiscal
                    </p>
                  </div>
                </div>
                <div className="client-data">
                  <h3><b>{returnedServiceOrder.original_service_order.client.name}</b></h3>
                  <div className="data1">
                    <div className="cpf-phones">
                      <strong>
                        CPF:
                        {' '}
                        {formatCpf(returnedServiceOrder.original_service_order.client.cpf)}
                      </strong>
                      <br />
                      <strong>
                        CNPJ:
                        {' '}
                        {formatCnpj(returnedServiceOrder.original_service_order.client.cnpj)}
                      </strong>
                      <p>Telefones: </p>
                      <p>{returnedServiceOrder.original_service_order.client.phone1}</p>
                      <p>{returnedServiceOrder.original_service_order.client.phone2}</p>
                      <p>{returnedServiceOrder.original_service_order.client.phone3}</p>
                    </div>
                    <div className="address">
                      <strong>
                        Endereco:
                      </strong>
                      <p>
                        CEP:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.zip_code}
                      </p>
                      <p>
                        Rua:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.address}
                        ,
                        {' '}
                        {returnedServiceOrder.original_service_order.client.number}
                      </p>
                      <p>
                        Bairo:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.neighborhood}
                      </p>
                      <p>
                        Cidade:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.city}
                        ,
                        {' '}
                        {returnedServiceOrder.original_service_order.client.state}
                      </p>
                    </div>
                    <div className="complement">
                      <br />
                      <p>
                        Complemento:
                        {' '}
                        {returnedServiceOrder.original_service_order.client.complement}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-data">
                  <h3><b>Retorno</b></h3>
                  <div className="data2">
                    <div className="product">
                      <p>
                        Aparelho:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_name}
                      </p>
                      <p>
                        Modelo:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_model}
                      </p>
                      <p>
                        Marca:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_brand}
                      </p>
                      <p>
                        Nº de série:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_serial}
                      </p>
                    </div>
                    <div className="accessories">
                      <p>
                        Acessórios:
                        {' '}
                        {returnedServiceOrder.original_service_order.product_accessories}
                      </p>
                    </div>
                    <div className="details">
                      <p>
                        Motivo do Retorno:
                        {' '}
                        {returnedServiceOrder.return_motive}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fix-data">
                  <h3><b>Conserto Retorno</b></h3>
                  <div className="data3">
                    <div className="fix">
                      <p>
                        Data do conserto:
                        {' '}
                        {returnedServiceOrder.fix_date ? formatDate(new Date(returnedServiceOrder.fix_date)) : '-'}
                      </p>
                      <p>
                        Técnico:
                        {' '}
                        {returnedServiceOrder.fix_technician_name}
                      </p>
                      <p>
                        Valor das peças:
                        {' '}
                        {formatValue(returnedServiceOrder.fix_value_pieces)}
                      </p>
                      <p>
                        Valor da mão de obra:
                        {' '}
                        {formatValue(returnedServiceOrder.fix_value_labor)}
                      </p>
                      <p>
                        Pagamento(s):
                        {' '}
                        {formatValue(totalUpfrontPayment)}
                      </p>
                    </div>
                    <div className="description">
                      <p>
                        {(returnedServiceOrder.fix_description
                          && returnedServiceOrder.fix_description.split('\n')
                            .join('; ')
                        )}
                      </p>
                    </div>
                    <div className="final">
                      <h3>
                        Valor total:
                        {' '}
                        {formatValue(returnedServiceOrder.fix_value_final)}
                      </h3>
                      <p>
                        Forma de pagamento:
                        {' '}
                        {returnedServiceOrder.fix_payment_method || '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <p>OBS.: Garantia do serviço executado valida por 90 dias mediante a apresentacao desta O.S.</p>
                </div>
              </div>
            </div>
          </>
        )}

        {normalServiceOrder.status === 107 || returnedServiceOrder.status === 204 || normalServiceOrder.status === 108 || returnedServiceOrder.status === 205 ? (
          <Buttons>
            <button
              className="print1"
              type="button"
              onClick={() => {
                handleShowPrint1();
                handlePrint1();
              }}
            >
              Imprimir O.S.

            </button>
            <button
              className="print2"
              type="button"
              onClick={() => {
                handleShowPrint2();
                handlePrint2();
              }}
            >
              Imprimir duas vias

            </button>
          </Buttons>
        ) : ((
          <Buttons>
            <button
              className="print1"
              type="button"
              onClick={() => {
                handleShowPrint1();
                handlePrint1();
              }}
            >
              Imprimir O.S.

            </button>
            <button
              className="print2"
              type="button"
              onClick={() => {
                handleShowPrint2();
                handlePrint2();
              }}
            >
              Imprimir duas vias

            </button>
            <button className="add-upfront" onClick={handleOpenCreateOSTransactionModal} type="submit">Pagamento antecipado</button>
            <button className="send" type="button" onClick={handleCloseOS}>Finalizar e Enviar para o caixa</button>
            <button className="cancel" type="button" onClick={() => (isNormalServiceOrder ? handleOpenCancelOSModal(normalServiceOrder.id) : handleOpenCancelOSModal(returnedServiceOrder.id))}>Cancelar O.S.</button>
          </Buttons>
        )) }
      </Content>
    </Container>
  );
}

export default OS;

import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import { useToast } from '../../hooks/toast';
import formatDate from '../../utils/formatDate';
import formatStringDate from '../../utils/formatStringDate';
import formatUSValue from '../../utils/formatUSValue';
import formatBRValue from '../../utils/formatBRValue';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import NormalServiceOrderStatus from '../../utils/NormalServiceOrderStatus';

import {
  Container, Content, StatusArea, Client,
} from './styles';

import MenuBar from '../../components/MenuBar';
import Input from '../../components/Input';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';

function UpdateNormalOS() {
  const formRef = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToast();

  const [serviceOrder, setServiceOrder] = useState({
    id: '',
    number: '',
    type: -1,
    status: -1,
    product_name: '',
    product_model: '',
    product_brand: '',
    product_serial: '',
    product_accessories: '',
    product_details: '',
    fix_description: '',
    fix_technician_name: '',
    fix_date: '',
    fix_value_labor: '',
    fix_value_final: '',
    created_at: '',
    updated_at: '',
    original_service_order: '',
    return_motive: '',
    client: {
      id: '',
      cpf: '',
      name: '',
      email: '',
      zip_code: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      phone1: '',
      phone2: '',
      phone3: '',
      created_at: '',
      updated_at: '',
    },
    transactions: [],
  });

  useEffect(() => {
    api.get(`/service-orders/normal/${id}`).then((response) => {
      const serviceOrderData = response.data;
      setServiceOrder(serviceOrderData);
    });
    formRef.current.setFieldValue('status', serviceOrder.status);
  }, [id, serviceOrder.status]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          product_name: Yup.string()
            .required('Digite algo para editar'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/service-orders/normal/${id}`, {
          status: data.status,
          product_name: data.product_name,
          product_model: data.product_model,
          product_brand: data.product_brand,
          product_serial: data.product_serial,
          product_accessories: data.product_accessories,
          product_details: data.product_details,
          fix_technician_name: data.fix_technician_name,
          fix_date: data.fix_date ? new Date(formatStringDate(data.fix_date)) : '',
          fix_description: data.fix_description,
          fix_value_pieces: formatUSValue(data.fix_value_pieces),
          fix_value_labor: formatUSValue(data.fix_value_labor),
          fix_value_final: formatUSValue(data.fix_value_final),
          fix_payment_method: data.fix_payment_method,
        });

        const newServiceOrder = response.data;

        addToast({
          type: 'success',
          title: 'O.S. Editada com sucesso!',
        });

        history.replace(`/os/${newServiceOrder.id}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          // return;
        }

        addToast({
          type: 'error',
          title: 'Erro na edicão da O.S.',
          description:
          'Ocorreu um erro ao editar a O.S. Cheque suas credenciais.',
        });
      }
    },
    [history, addToast, id],
  );

  return (
    <Container>
      <MenuBar />

      <Content>
        <h1>
          Editar O.S.:
          {' '}
          {serviceOrder.number}
        </h1>

        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            product_name: serviceOrder.product_name,
            product_model: serviceOrder.product_model,
            product_brand: serviceOrder.product_brand,
            product_serial: serviceOrder.product_serial,
            product_accessories: serviceOrder.product_accessories,
            product_details: serviceOrder.product_details,
            fix_technician_name: serviceOrder.fix_technician_name,
            fix_date: serviceOrder.fix_date ? formatDate(new Date(serviceOrder.fix_date)) : '',
            fix_description: serviceOrder.fix_description,
            fix_value_pieces: formatBRValue(serviceOrder.fix_value_pieces),
            fix_value_labor: formatBRValue(serviceOrder.fix_value_labor),
            fix_value_final: formatBRValue(serviceOrder.fix_value_final),
            fix_payment_method: serviceOrder.fix_payment_method,
          }}
        >
          <StatusArea>
            <strong>Status: </strong>
            <Select name="status">
              <option value={String(NormalServiceOrderStatus.BUDGET_PENDING)}>
                Pendente de Orçamento
              </option>
              <option value={String(NormalServiceOrderStatus.BUDGET_CREATED)}>
                Orçamento Criado
              </option>
              <option value={String(NormalServiceOrderStatus.BUDGET_APPROVED)}>
                Orçamento Aprovado
              </option>
              <option value={String(NormalServiceOrderStatus.PARTS_PENDING)}>
                Pendente de Peça
              </option>
              <option value={String(NormalServiceOrderStatus.FIXED)}>
                Consertada
              </option>
              <option value={String(NormalServiceOrderStatus.CLIENT_NOTIFIED)}>
                Cliente Notificado
              </option>
              <option value={String(NormalServiceOrderStatus.PRODUCT_DELIVERED_WITHOUT_PAYMENT)}>
                Produto Entregue Sem Recebimento
              </option>
              <option value={String(NormalServiceOrderStatus.BUDGET_NOT_APPROVED)}>
                Orçamento Não Aprovado
              </option>
              <option value={String(NormalServiceOrderStatus.PRODUCT_NOT_DEFECTIVE)}>
                Produto Sem Defeito
              </option>
              <option value={String(NormalServiceOrderStatus.BUDGET_NOT_WORTH_IT)}>
                Não Compensa
              </option>
              <option value={String(NormalServiceOrderStatus.PARTS_NOT_AVAILABLE)}>
                Sem Peças de Reposição
              </option>
            </Select>
          </StatusArea>

          <Client>
            <strong>{serviceOrder.client.name}</strong>
            <p>
              <b>CPF:</b>
              {' '}
              {serviceOrder.client.cpf || '-'}
            </p>
            <br />
            <p>
              <b>Contato:</b>
            </p>
            <p>
              Telefones:
              {' '}
              {serviceOrder.client.phone1 || '-'}
              {' '}
              /
              {' '}
              {serviceOrder.client.phone2 || '-'}
              {' '}
              /
              {' '}
              {serviceOrder.client.phone3 || '-'}
            </p>
            <br />

            <p>
              <b>Endereço:</b>
            </p>
            <p>
              {' '}
              CEP:
              {' '}
              {serviceOrder.client.zip_code || '-'}
              {' '}
            </p>
            <p>
              {' '}
              Rua:
              {' '}
              {serviceOrder.client.address || '-'}
            </p>
            <p>
              {' '}
              Número:
              {' '}
              {serviceOrder.client.number || '-'}
            </p>
            <p>
              {' '}
              Bairro:
              {' '}
              {serviceOrder.client.neighborhood || '-'}
            </p>
            <p>
              {' '}
              Complemento:
              {' '}
              {serviceOrder.client.complement || '-'}
            </p>
          </Client>

          <section className="product-data">
            <strong>Informações do Produto</strong>

            <div className="name-model-brand">
              <div className="name">
                <p>Nome</p>
                <Input
                  type="text"
                  name="product_name"
                  containerStyle={{
                    width: '350px',
                    marginRight: '16px',
                  }}
                />
              </div>
              <div className="model">
                <p>Modelo</p>
                <Input
                  type="text"
                  name="product_model"
                  containerStyle={{
                    width: '300px',
                    marginRight: '16px',
                  }}
                />
              </div>
              <div className="brand">
                <p>Marca</p>
                <Input
                  type="text"
                  name="product_brand"
                  inputStyle={{
                    width: '134px',
                  }}
                  containerStyle={{
                    width: '150px',
                    marginRight: '16px',
                  }}
                />
              </div>
            </div>

            <div className="number-accessories">
              <div className="number">
                <p>Nº de Série</p>
                <Input
                  type="text"
                  name="product_serial"
                  containerStyle={{
                    marginRight: '16px',
                  }}
                />
              </div>
              <div className="accessories">
                <p>Acessórios</p>
                <Input
                  type="text"
                  name="product_accessories"
                  containerStyle={{
                    width: '422px',
                  }}
                />
              </div>
            </div>

            <div className="details">
              <p>Defeito Reclamado</p>
              <Input
                name="product_details"
                type="text"
                containerStyle={{
                  width: '690px',
                  marginBottom: '24px',
                }}
              />
            </div>

          </section>

          <section className="repair-data">
            <strong>Informações do Conserto</strong>

            <div className="repair-form">
              <div>
                <div className="repair">
                  <div className="name">
                    <p>Técnico</p>
                    <Input
                      name="fix_technician_name"
                      containerStyle={{
                        width: '200px',
                        marginRight: '16px',
                      }}
                      type="text"
                    />
                  </div>
                  <div className="date">
                    <p>Data</p>
                    <Input
                      name="fix_date"
                      containerStyle={{
                        width: '200px',
                      }}
                      type="text"
                    />
                  </div>
                </div>

                <div className="details">
                  <p>Peças</p>
                  <TextArea
                    name="fix_description"
                    containerStyle={{
                      width: '438px',
                      marginBottom: '16px',
                      height: '190px',
                    }}
                    textAreaStyle={{
                      height: '180px',
                    }}
                    type="text"
                  />
                </div>
              </div>

              <div className="prices">
                <div className="price1">
                  <p>Valor das Peças</p>
                  <Input
                    name="fix_value_pieces"
                    containerStyle={{
                      width: '200px',
                      marginTop: '8px',
                    }}
                    type="text"
                  />
                </div>
                <div className="price2">
                  <p>Mão de Obra</p>
                  <Input
                    name="fix_value_labor"
                    containerStyle={{
                      width: '200px',
                      marginTop: '8px',
                    }}
                    type="text"
                  />
                </div>
                <div className="price3">
                  <p>Valor Final</p>
                  <Input
                    name="fix_value_final"
                    containerStyle={{
                      width: '200px',
                      marginTop: '8px',
                    }}
                    type="text"
                  />
                </div>
                <div className="payment">
                  <p>Forma de Pagamento</p>
                  <Input
                    name="fix_payment_method"
                    containerStyle={{
                      width: '200px',
                      marginTop: '8px',
                    }}
                    type="text"
                  />
                </div>
              </div>
            </div>

            <button type="submit">Salvar</button>

          </section>
        </Form>

      </Content>
    </Container>
  );
}

export default UpdateNormalOS;

import React, {
  useState, useEffect, useRef, useCallback, useMemo,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as printStyle from './printStyle.css';
import getValidationErrors from '../../utils/getValidationErrors';
import formatDate from '../../utils/formatDate';
import formatCpf from '../../utils/formatCpf';
import formatCnpj from '../../utils/formatCnpj';
import formatUSValue from '../../utils/formatUSValue';
import getOrderStatusStringFromInt from '../../utils/getOrderStatusStringFromInt';
import api from '../../services/api';
import isManager from '../../utils/isManager';
import { useToast } from '../../hooks/toast';

import headerLogo from '../../assets/headerLogo.png';

import {
  Container, Content, OrderData, Buttons,
} from './styles';

import MenuBar from '../../components/MenuBar';
import Input from '../../components/Input';
import formatValue from '../../utils/formatValue';

const modalOrderStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #091021',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '270px',
    // height: '200px',
  },
  selector: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    background: 'transparent',
    borderRadius: '10px',
    border: '2px solid #091021',
    color: '#091021',

    padding: '0 8px',
    width: '230px',
    height: '30px',
  },
  deleteButton: {
    background: '#c53030',
    color: '#ffff',
    border: '2px solid #c53030',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
  },
  cancelButton: {
    background: 'transparent',
    color: '#091021',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
    marginLeft: '72px',
  },
}));

const createOrderTransactionStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #091021',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '350px',
    height: '300px',
  },
  selector: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  input: {
    background: 'transparent',
    borderRadius: '10px',
    border: '2px solid #091021',
    color: '#091021',
    marginTop: '8px',

    padding: '0 8px',
    width: '230px',
    height: '30px',
    marginBottom: '8px',

  },
  createButton: {
    background: '#091021',
    color: '#ffff',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
  },
  cancelButton: {
    background: 'transparent',
    color: '#091021',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    marginLeft: '60px',
    padding: '0 16px',
  },
}));

function Order() {
  const { id } = useParams();
  const componentRef1 = useRef();
  const componentRef2 = useRef();
  const deleteOrderRef = useRef(null);
  const cancelOrderRef = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [order, setOrder] = useState({
    id: '',
    number: '',
    status: -1,
    piece_code: '',
    piece_name: '',
    piece_value: '',
    product_model: '',
    created_at: '',
    updated_at: '',
    transactions: [],
    client: {
      id: '',
      cpf: '',
      cnpj: '',
      name: '',
      email: '',
      zip_code: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      phone1: '',
      phone2: '',
      phone3: '',
      created_at: '',
      updated_at: '',
    },
  });

  useEffect(() => {
    const getOrder = async () => {
      try {
        const response = await api.get(`/orders/${id}`);

        const orderData = response.data;

        setOrder(orderData);
      } catch (err) {
        console.log(err);
      }
    };

    getOrder();

    const print1 = document.getElementById('print1');
    const print2 = document.getElementById('print2');

    print1.style.display = 'none';
    print2.style.display = 'none';
  }, [id]);

  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
    pageStyle: '',
    onAfterPrint: () => handleHidePrint1(),
  });

  const handleShowPrint1 = useCallback(() => {
    const print1 = document.getElementById('print1');

    print1.style.display = '';
  }, []);

  const handleHidePrint1 = useCallback(() => {
    const print1 = document.getElementById('print1');

    print1.style.display = 'none';
  }, []);

  const handlePrint2 = useReactToPrint({
    content: () => componentRef2.current,
    pageStyle: '',
    onAfterPrint: () => handleHidePrint2(),
  });

  const handleShowPrint2 = useCallback(() => {
    const print2 = document.getElementById('print2');

    print2.style.display = '';
  }, []);

  const handleHidePrint2 = useCallback(() => {
    const print2 = document.getElementById('print2');

    print2.style.display = 'none';
  }, []);

  const totalUpfrontPayment = useMemo(() => {
    const balance = order.transactions
      .reduce((accumulator, currentTransaction) => accumulator + Number(currentTransaction.value), 0.0);
    return balance;
  }, [order.transactions]);

  // DELETAR PEDIDO
  const deleteOrderClasses = modalOrderStyles();
  const [openDeleteOrderModal, setOpenDeleteOrderModal] = React.useState(false);
  const [deleteOrderModalData, setDeleteOrderModalData] = useState();

  const handleDeleteOrder = useCallback(async () => {
    if (deleteOrderModalData) {
      try {
        deleteOrderRef.current.setErrors({});
        const data = deleteOrderRef.current.getData();

        const schema = Yup.object().shape({
          username: Yup.string()
            .required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          username, password,
        } = data;

        const authenticationResponse = await api.post('sessions', {
          username,
          password,
        });
        const { user, token } = authenticationResponse.data;

        if (!isManager(user.authority_level)) {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
                'Digite uma credencial de gerente válida',
          });
          return;
        }

        await api.delete(`/orders/${deleteOrderModalData}`, {
          data: {
            confirm_username: username,
            confirm_password: password,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        setOpenDeleteOrderModal(false);

        addToast({
          type: 'success',
          title: 'Pedido deletado com sucesso!',
        });

        history.replace('/consulta');

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          deleteOrderRef.current.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao excluir Pedido',
          description:
              'Cheque os valores digitados.',
        });
      }
    }
  }, [addToast, deleteOrderModalData, history]);

  const handleOpenDeleteOrderModal = (osId) => {
    setDeleteOrderModalData(osId);
    setOpenDeleteOrderModal(true);
  };
  const handleCloseDeleteOrderModal = () => {
    setOpenDeleteOrderModal(false);
  };

  // CANCELAR PEDIDO
  const cancelOrderClasses = modalOrderStyles();
  const [openCancelOrderModal, setOpenCancelOrderModal] = React.useState(false);
  const [cancelOrderModalData, setCancelOrderModalData] = useState();

  const handleCancelOrder = useCallback(async () => {
    if (cancelOrderModalData) {
      try {
        cancelOrderRef.current.setErrors({});
        const data = cancelOrderRef.current.getData();

        const schema = Yup.object().shape({
          cancel_motive: Yup.string().required(),
          username: Yup.string()
            .required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          username, password, cancel_motive,
        } = data;

        const authenticationResponse = await api.post('sessions', {
          username,
          password,
        });
        const { user, token } = authenticationResponse.data;

        if (!isManager(user.authority_level)) {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
                'Digite uma credencial de gerente válida',
          });
          return;
        }

        await api.post('transactions/cancel/order', {
          order_id: cancelOrderModalData,
          cancel_motive,
          date: new Date(),
          confirm_username: username,
          confirm_password: password,
        },
        {
          authorization: `Bearer ${token}`,
        });

        setOpenCancelOrderModal(false);

        addToast({
          type: 'success',
          title: 'Pedido cancelado com sucesso!',
        });

        history.replace('/consulta');

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          cancelOrderRef.current.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao cancelar Pedido',
          description:
              'Cheque os valores digitados.',
        });
      }
    }
  }, [addToast, cancelOrderModalData, history]);

  const handleOpenCancelOrderModal = (osId) => {
    setCancelOrderModalData(osId);
    setOpenCancelOrderModal(true);
  };
  const handleCloseCancelOrderModal = () => {
    setOpenCancelOrderModal(false);
  };

  // ENVIAR PEDIDO PARA O CAIXA
  const handleCloseOrder = useCallback(async () => {
    try {
      const order_id = order.id;

      await api.post('transactions/close/order', {
        order_id,
        date: new Date(),
        description: '',
      });

      addToast({
        type: 'success',
        title: 'Pedido enviado ao caixa!',
      });

      history.replace('/consulta');

      return;
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao enviar pedido para o caixa',
        description:
              'Cheque os valores digitados. Para enviar um pedido ao caixa, ele deve conter: Valor e Forma de Pagamento',
      });
    }
  }, [addToast, history, order.id]);

  // ADICIONA PAGAMENTO ANTECIPADO NO PEDIDO
  const createOrderTransactionFormRef = useRef(null);
  const createOrderTransactionClasses = createOrderTransactionStyles();
  const [openCreateOrderTransactionModal, setOpenCreateOrderTransactionModal] = React.useState(false);

  const handleCreateOrderTransaction = useCallback(async (data) => {
    try {
      createOrderTransactionFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        description: Yup.string()
          .required('Descrição obrigatória'),
        value: Yup.string()
          .required('Valor obrigatório'),
        payment_method: Yup.string()
          .required('Valor obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      const {
        value, description, payment_method,
      } = data;

      await api.post('/transactions/upfront-payment/order', {
        order_id: order.id,
        value: formatUSValue(value),
        description,
        date: new Date(),
        payment_method,
      });

      setOpenCreateOrderTransactionModal(false);

      await addToast({
        type: 'success',
        title: 'Pagamento antecipado enviado ao caixa!',
      });

      return;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        createOrderTransactionFormRef.current.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao criar pagamento antecipado',
        description:
            'Cheque os valores digitados.',
      });
    }
  }, [addToast, order.id, history]);

  const handleOpenCreateOrderTransactionModal = () => {
    setOpenCreateOrderTransactionModal(true);
  };

  const handleCloseCreateOrderTransactionModal = () => {
    setOpenCreateOrderTransactionModal(false);
  };

  return (
    <Container>
      <MenuBar />

      <div>

        <Modal
          className={createOrderTransactionClasses.modal}
          open={openCreateOrderTransactionModal}
          onClose={handleCloseCreateOrderTransactionModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openCreateOrderTransactionModal}>
            <div className={createOrderTransactionClasses.paper}>
              <h2>
                <b>Criar Pagamento Antecipado</b>
              </h2>
              <br />

              <div>
                <Form ref={createOrderTransactionFormRef} onSubmit={handleCreateOrderTransaction}>
                  <div>
                    <p>Descrição: </p>
                    <Input name="description" className={createOrderTransactionClasses.input} type="text" />
                    <p style={{ marginTop: '8px' }}>Forma de Pagamento: </p>
                    <Input name="payment_method" className={createOrderTransactionClasses.input} inputStyle={{ width: '200px' }} type="text" />
                    <p style={{ marginTop: '8px' }}>Valor: </p>
                    <Input name="value" className={createOrderTransactionClasses.input} type="text" />
                  </div>

                  <button
                    type="submit"
                    className={createOrderTransactionClasses.createButton}
                  >
                    Adicionar ao caixa
                  </button>
                  <button
                    onClick={handleCloseCreateOrderTransactionModal}
                    type="button"
                    className={createOrderTransactionClasses.cancelButton}
                  >
                    Cancelar
                  </button>
                </Form>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          className={deleteOrderClasses.modal}
          open={openDeleteOrderModal}
          onClose={handleCloseDeleteOrderModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDeleteOrderModal}>
            <div className={deleteOrderClasses.paper}>
              <h2>
                <b>
                  Deletar Pedido:
                  {' '}
                  {order.number}
                </b>
              </h2>
              <br />

              <Form ref={deleteOrderRef}>
                <strong>
                  <b>Confirme login do gerente:</b>
                </strong>
                <div className={deleteOrderClasses.inputs}>
                  <Input name="username" className={deleteOrderClasses.input} type="text" placeholder="Usuario" />
                  <Input name="password" className={deleteOrderClasses.input} type="password" placeholder="Senha" />
                </div>

                <button
                  onClick={() => handleDeleteOrder()}
                  type="button"
                  className={deleteOrderClasses.deleteButton}
                >
                  Excluir
                </button>

                <button
                  onClick={handleCloseDeleteOrderModal}
                  type="button"
                  className={deleteOrderClasses.cancelButton}
                >
                  Voltar
                </button>
              </Form>
            </div>
          </Fade>
        </Modal>

        <Modal
          className={cancelOrderClasses.modal}
          open={openCancelOrderModal}
          onClose={handleCloseCancelOrderModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openCancelOrderModal}>
            <div className={cancelOrderClasses.paper}>
              <h2>
                <b>
                  Cancelar Pedido:
                  {' '}
                  {order.number}
                </b>
              </h2>
              <br />

              <Form ref={cancelOrderRef}>
                <strong>
                  <b>Motivo do cancelamento:</b>
                </strong>
                <div className={cancelOrderClasses.inputs}>
                  <Input name="cancel_motive" className={cancelOrderClasses.input} type="text" placeholder="Motivo" />
                </div>
                <br />
                <strong>
                  <b>Confirme login do gerente:</b>
                </strong>
                <div className={cancelOrderClasses.inputs}>
                  <Input name="username" className={cancelOrderClasses.input} type="text" placeholder="Usuario" />
                  <Input name="password" className={cancelOrderClasses.input} type="password" placeholder="Senha" />
                </div>

                <button
                  onClick={() => handleCancelOrder()}
                  type="button"
                  className={cancelOrderClasses.deleteButton}
                >
                  Cancelar
                </button>

                <button
                  onClick={handleCloseCancelOrderModal}
                  type="button"
                  className={cancelOrderClasses.cancelButton}
                >
                  Voltar
                </button>
              </Form>
            </div>
          </Fade>
        </Modal>

      </div>

      <Content>
        <h1>{order.number || ''}</h1>

        <p>
          <b>Status:</b>
          {' '}
          {getOrderStatusStringFromInt(order.status)}
        </p>
        <div className="edit-delete">
          {order.status === 103 || order.status === 104 ? (
            <p style={{ visibility: 'hidden', marginRight: '30px' }}>Editar</p>
          ) : (
            <a className="edit" href={`/editar-pedido/${order.id}`}>Editar</a>
          )}
          <button type="button" onClick={() => (handleOpenDeleteOrderModal(order.id))}>
            <FiTrash2 />
          </button>
        </div>

        <>
          <OrderData>
            <h2><b>Dados do Cliente</b></h2>
            <p>
              <b>Nome:</b>
              {' '}
              {order.client.name || '-'}
            </p>
            <p>
              <b>CPF:</b>
              {' '}
              {formatCpf(order.client.cpf) || '-'}
            </p>
            <p>
              <b>CNPJ:</b>
              {' '}
              {formatCnpj(order.client.cnpj) || '-'}
            </p>
            <br />
            <p>
              <b>Contato:</b>
            </p>
            <p>
              Telefones:
              {' '}
              {order.client.phone1 || '-'}
              {' '}
              /
              {' '}
              {order.client.phone2 || '-'}
              {' '}
              /
              {' '}
              {order.client.phone3 || '-'}
            </p>
            <br />

            <p>
              <b>Endereço:</b>
            </p>
            <p>
              {' '}
              CEP:
              {' '}
              {order.client.zip_code || '-'}
              {' '}
            </p>
            <p>
              {' '}
              Rua:
              {' '}
              {order.client.address || '-'}
            </p>
            <p>
              {' '}
              Número:
              {' '}
              {order.client.number || '-'}
            </p>
            <p>
              {' '}
              Bairro:
              {' '}
              {order.client.neighborhood || '-'}
            </p>
            <p>
              {' '}
              Complemento:
              {' '}
              {order.client.complement || '-'}
            </p>
            <br />

            <h2><b>Dados do Pedido</b></h2>
            <p>
              Número:
              {' '}
              {order.number || '-'}
            </p>
            <p>
              Data do pedido:
              {' '}
              {formatDate(Date(order.created_at)) || '-'}
            </p>
            <p>
              Status:
              {' '}
              {getOrderStatusStringFromInt(order.status) || '-'}
            </p>
            <br />
            <p>
              Nome da peca:
              {' '}
              {order.piece_name || '-'}
            </p>
            <p>
              Código da peca:
              {' '}
              {order.piece_code || '-'}
            </p>
            <p>
              Modelo do produto:
              {' '}
              {order.product_model || '-'}
            </p>
            <p>
              Valor:
              {' '}
              {formatValue(order.piece_value) || '-'}
            </p>

            <br />
            <p>
              Data do pedido do fabricante:
              {' '}
              {order.manufacturer_date ? formatDate(new Date(order.manufacturer_date)) : '-'}
            </p>
            <p>
              Código do pedido do fabricante:
              {' '}
              {order.manufacturer_code || '-'}
            </p>

            <br />

            <p>
              Forma de pagamento:
              {' '}
              {order.payment_method || '-'}
            </p>

            <br />

            <p>
              <b>Pagamentos:</b>
            </p>

            {order.transactions.length > 0 ? (
              <>
                {order.transactions.map((transaction) => (
                  <>
                    <p>
                      Valor:
                      {' '}
                      {formatValue(transaction.value)}
                    </p>
                    <p>
                      Descrição:
                      {' '}
                      {transaction.description}
                    </p>
                    <p>
                      Data:
                      {' '}
                      {formatDate(Date(transaction.created_at)) || '-'}
                    </p>
                    <br />
                  </>
                ))}
              </>
            ) : 'Sem pagamentos antecipados'}

          </OrderData>

          <div ref={componentRef1} id="print1">
            <div className="main">
              <link type="stylesheet" url={printStyle} />
              <div className="header">
                <img src={headerLogo} alt="Logo" />
                <div className="header-right">
                  <p>
                    Pedido:
                  </p>
                  <h1>
                    <b>{order.number}</b>
                  </h1>
                </div>
              </div>
              <div className="client-data">
                <h3><b>{order.client.name}</b></h3>
                <div className="data1">
                  <div className="cpf-phones">
                    <strong>
                      CPF:
                      {' '}
                      {formatCpf(order.client.cpf)}
                    </strong>
                    <br />
                    <strong>
                      CNPJ:
                      {' '}
                      {formatCnpj(order.client.cnpj)}
                    </strong>
                    <p>Telefones: </p>
                    <p>{order.client.phone1}</p>
                    <p>{order.client.phone2}</p>
                    <p>{order.client.phone3}</p>
                  </div>
                  <div className="address">
                    <strong>
                      Endereco:
                    </strong>
                    <p>
                      CEP:
                      {' '}
                      {order.client.zip_code}
                    </p>
                    <p>
                      Rua:
                      {' '}
                      {order.client.address}
                      ,
                      {' '}
                      {order.client.number}
                    </p>
                    <p>
                      Bairo:
                      {' '}
                      {order.client.neighborhood}
                    </p>
                    <p>
                      Cidade:
                      {' '}
                      {order.client.city}
                      ,
                      {' '}
                      {order.client.state}
                    </p>
                  </div>
                  <div className="complement">
                    <br />
                    <p>
                      Complemento:
                      {' '}
                      {order.client.complement}
                    </p>
                  </div>
                </div>
              </div>
              <div className="order-data">
                <h3><b>Pedido</b></h3>
                <div className="data-value">
                  <div className="order">
                    <p>
                      Data do pedido:
                      {' '}
                      {formatDate(Date(order.created_at))}
                    </p>
                    <p>
                      Nome da Peca:
                      {' '}
                      {order.piece_name}
                    </p>
                    <p>
                      Código da peca:
                      {' '}
                      {order.piece_code}
                    </p>
                    <p>
                      Modelo do produto:
                      {' '}
                      {order.product_model}
                    </p>
                    <p>
                      Forma de pagamento:
                      {' '}
                      {order.payment_method || '-'}
                    </p>
                    <p>
                      Pagamento(s) antecipado(s):
                      {' '}
                      {formatValue(totalUpfrontPayment)}
                    </p>
                    <br />
                    <p>
                      Data do pedido do fabricante:
                      {' '}
                      {order.manufacturer_date ? formatDate(new Date(order.manufacturer_date)) : '-'}
                    </p>
                    <p>
                      Código do pedido do fabricante:
                      {' '}
                      {order.manufacturer_code || '-'}
                    </p>
                  </div>
                  <div className="value">
                    <p>
                      Valor total:
                      {' '}
                      {formatValue(order.piece_value)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="footer">
                <p>OBS.: Garantia do serviço executado valida por 90 dias mediante a apresentacao desta O.S.</p>
              </div>
            </div>
          </div>

          <div ref={componentRef2} id="print2">
            <div className="main">
              <link type="stylesheet" url={printStyle} />
              <div className="destinatario">
                <h3><b>Destinatário:</b></h3>
                <div className="data1">
                  <div className="address">
                    <h3><b>{order.client.name}</b></h3>
                    <p>
                      Telefone(s):
                      {' '}
                      {order.client.phone1 || '-'}
                      {' '}
                      /
                      {' '}
                      {order.client.phone2 || '-'}
                      {' '}
                      /
                      {' '}
                      {order.client.phone3 || '-'}
                    </p>
                    <p>
                      Rua
                      {' '}
                      {order.client.address}
                      ,
                      {' '}
                      {order.client.number}
                    </p>
                    <p>
                      Bairro
                      {' '}
                      {order.client.neighborhood}
                    </p>
                    <p>
                      {order.client.city}
                      ,
                      {' '}
                      {order.client.state}
                    </p>
                    <p>
                      {order.client.complement}
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <div className="remetente">
                <h3><b>Remetente:</b></h3>
                <div className="data1">
                  <div className="address">
                    <h3><b>Eletro Rádio Mark</b></h3>
                    <p>
                      Telefone(s): 4733511458 / 47991078450
                    </p>
                    <p>
                      Rua Florianópolis, 1239
                    </p>
                    <p>
                      Bairro Primeiro de Maio
                    </p>
                    <p>
                      Brusque, SC
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </>

        {order.status === 103 || order.status === 104 ? (
          <Buttons>
            <button
              className="print1"
              type="button"
              onClick={() => {
                handleShowPrint1();
                handlePrint1();
              }}
            >
              Imprimir Pedido

            </button>
            <button
              className="print2"
              type="button"
              onClick={() => {
                handleShowPrint2();
                handlePrint2();
              }}
            >
              Imprimir Endereço

            </button>
          </Buttons>
        ) : ((
          <Buttons>
            <button
              className="print1"
              type="button"
              onClick={() => {
                handleShowPrint1();
                handlePrint1();
              }}
            >
              Imprimir Pedido

            </button>
            <button
              className="print2"
              type="button"
              onClick={() => {
                handleShowPrint2();
                handlePrint2();
              }}
            >
              Imprimir Endereço

            </button>
            <button className="add-upfront" onClick={handleOpenCreateOrderTransactionModal} type="submit">Pagamento antecipado</button>
            <button className="send" type="button" onClick={handleCloseOrder}>Finalizar e Enviar para o caixa</button>
            <button className="cancel" type="button" onClick={() => (handleOpenCancelOrderModal(order.id))}>Cancelar</button>
          </Buttons>
        )) }
      </Content>
    </Container>
  );
}

export default Order;
